import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"

const DepotActions = props => {
    return (
        <div className="d-flex justify-content-between mb-4 w-100">
            <Col lg={4}>
                <Link to="/add-inslug">
                    <div className="mx-3">
                        <Button color="primary" className="py-3 w-100">
                            {props.t("New Inslug")}
                        </Button>
                    </div>
                </Link>
            </Col>
            <Col lg={4}>
                <Link to="/add-uitslug">
                    <div className="mx-3">
                        <Button color="warning" className="py-3 w-100">
                            {props.t("New Uitslug")}
                        </Button>
                    </div>
                </Link>
            </Col>
            <Col lg={4}>
                <Link to="/add-heleven">
                    <div className="mx-3">
                        <Button color="success" className="py-3 w-100">
                            {props.t("New Heleven")}
                        </Button>
                    </div>
                </Link>
            </Col>
        </div>
    );
};

DepotActions.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
}

export default withTranslation()(DepotActions)