// inslugItem/actions.js

import { ADD_INSLUG_ITEM_ROW, SET_INSLUG_ITEM_DATA, RESET_INSLUG_ITEMS, REMOVE_LAST_INSLUG_ITEM_ROW, SET_INSLUG_ITEMS_ALL } from './actionTypes';

// Action to add a new row of inslug items
export const addInslugItemRow = () => {
  return {
    type: ADD_INSLUG_ITEM_ROW,
    payload: {
      client_no: null,
      container_no: null,
      description: null,
      leverage_charge: null,
    },
  };
};

// Action to set data in the inslug items form
export const setInslugItemData = (index, field, value) => {
  return {
    type: SET_INSLUG_ITEM_DATA,
    payload: { index, field, value },
  };
};

// Son satırı silme aksiyonu
export const removeLastInslugItemRow = () => {
  return {
    type: REMOVE_LAST_INSLUG_ITEM_ROW
  };
};

// Action to reset the inslug items
export const resetInslugItems = () => {
  return {
    type: RESET_INSLUG_ITEMS,
  };
};

// Action to reset the inslug items
export const setInslugItemsAll = (data) => {
  return {
    type: SET_INSLUG_ITEMS_ALL,
    payload: data
  };
};


