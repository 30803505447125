// /src/services/inslugService.js
import axios from 'axios';
import API_BASE_URL from '../config';
const API_DEPOT = '/depot'; 
const API_INSLUG = '/inslug'; 
const API_INSLUG_INFO = '/info'; 
const API_INSLUG_GET_ONE = '/one-inslug-with-items'; 
const API_INSLUG_GET = '/get'; 
const API_INSLUG_CREATE = '/create'; 
const API_INSLUG_UPDATE = '/update'; 

/**
 * Inslug ekleme fonksiyonu
 * @param {Object} inslugData - Formda girilen inslug verileri
 * @returns {Promise} - API cevabı
 */
export const createInslug = async (inslugData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}${API_DEPOT}${API_INSLUG}${API_INSLUG_CREATE}`, inslugData);
    return response.data;
  } catch (error) {
    console.error('Inslug eklenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Inslug ekleme fonksiyonu
 * @param {Object} id - Formda girilen inslug verileri
 * @returns {Promise} - API cevabı
 */
export const getInslug = async (id) => {
  try {
    console.log("iddddddd : " + id);
    const response = await axios.post(`${API_BASE_URL}${API_DEPOT}${API_INSLUG}${API_INSLUG_GET_ONE}`, {id});
    return response.data;
  } catch (error) {
    console.error('Inslug getirilirken bir hata oluştu', error);
    throw error;
  }
};

/**
 * Mevcut tüm Inslug'ları getirme fonksiyonu
 * @returns {Promise} - API cevabı
 */
export const getInslugs = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}${API_DEPOT}${API_INSLUG}${API_INSLUG_GET}`);
    return response.data;
  } catch (error) {
    console.error('Inslug getirilirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Inslug eklemek için gerekli olan verileri çeken fonskiyon
 * @returns {Promise} - API cevabı
 */
export const inslugInfo = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}${API_DEPOT}${API_INSLUG}${API_INSLUG_INFO}`);
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.error('Inslug getirilirken bir hata oluştu:', error);
      throw error;
    }
  };

/**
 * Belirli bir inslug'ı ID'ye göre güncelleme fonksiyonu
 * @param {string} id - Güncellenecek Inslug'un ID'si
 * @param {Object} inslugData - Güncellenmiş Inslug verileri
 * @returns {Promise} - API cevabı
 */
export const updateInslug = async (inslugData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}${API_DEPOT}${API_INSLUG}${API_INSLUG_UPDATE}`, inslugData);
    return response.data;
  } catch (error) {
    console.error('Inslug güncellenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Belirli bir inslug'ı ID'ye göre silme fonksiyonu
 * @param {string} id - Silinecek Inslug'un ID'si
 * @returns {Promise} - API cevabı
 */
export const deleteInslug = async (id) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/inslugs/${id}`);
    return response.data;
  } catch (error) {
    console.error('Inslug silinirken bir hata oluştu:', error);
    throw error;
  }
};
