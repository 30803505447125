import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { updateInslug } from "../../../../service/inslugService";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import { closeModal, openModal } from "store/modal/actions";

const UpdateInslugActions = props => {
    const dispatch = useDispatch();

    // Redux state
    const updateInslugData = useSelector((state) => state.updateInslug);
    const updateInslugItemsData = useSelector((state) => state.updateInslugItems?.updateInslugItems || []);

    const [modal, setModal] = useState(false); // Modal açılıp kapanma durumu

    useEffect(() => {
        console.log("redux newInslug güncellendi");
        console.log(updateInslugData);
    }, [updateInslugData]);

    useEffect(() => {
        console.log("redux inslugItems güncellendi");
        console.log(updateInslugItemsData);
    }, [updateInslugItemsData]);

    const validation = () => {
        if (!updateInslugData.client_no) {
            toast.error(props.t("error-company-empty"), { position: "top-right" });
            return false; 
        }
/*         if (!updateInslugData.document_no) {
            toast.error(props.t("error-document-empty"), { position: "top-right" });
            return false; 
        } */
        return true;
    }

    const sendData = async () => {
        const validationResult = validation();
        if(validationResult === false) {
            return;
        }
        dispatch(
            openModal({
                title: props.t("modal-update-title"),
                body: props.t("modal-update-body"),
                onConfirm: async () => {
                    try {
                        // Eğer client_no null değilse, işlem yapılır
                        const inslugData = createInslugJson(updateInslugData);
                        const inslugItemData = extractSelectedFields(updateInslugItemsData);
                        const data = { inslug: inslugData, inslugItems: inslugItemData };
                        console.log("hepsilkdsjhfgdsfjksdfhgsdjkjdsfhgdsjkjdshfg");
                        console.log(data);
                        const response = await updateInslug(data)
                            .then(() => {
                                toast.success(props.t("toast-success-update-data"), { position: "top-right" });
                                setModal(false); // Modalı kapatıyoruz
                                /* dispatch(resetUpdateInslugForm()); // Formu sıfırla
                                dispatch(resetUpdateInslugItems());  
                                dispatch(setSelectedUpdateProducts([])); */
                                console.log("sıfırlanma işlemi oldu");
                                console.log(updateInslugData);
                                console.log(updateInslugItemsData);
                            })
                        dispatch(closeModal()); // Close the modal after successful update
                    } catch (error) {
                        console.error("Hata:", error);
                        setModal(false); // Modalı kapatıyoruz
                        toast.error(props.t("toast-error-update-data"), { position: "top-right" });
                        dispatch(closeModal()); // Close the modal after failure
                    }
                }
            })
        );
    };

const createInslugJson = (data) => {
    const inslugData = {
        id: data.id,
        client_no: data.client_no,
        transport_company_no: data.transport_company_no,
        booking_date: data.booking_date,
        document_no: data.document_no,
        extern_document_no: data.extern_document_no,
        agency_reference: data.agency_reference,
        description: data.description,
        eta: data.eta,
        closing_out: data.closing_out,
        closing_in: data.closing_in,
        customs_status_id: data.customs_status_id,
        supplier_company_no: data.supplier_company_no,
        carrier_type_id: data.carrier_type_id,
        loading_status_id: data.loading_status_id,
        process_status_id: data.process_status_id,
        count: parseInt(data.count, 10),
    };
    return inslugData;
};

function extractSelectedFields(data) {
    // Yeni JSON arrayini oluştur
    const result = data.map(item => {
        return {
            id: item.id,
            client_product_id: item.client_product_id || null,
            container_no: item.container_no || null,
            leverage_charge: item.leverage_charge || null,
            description: item.description || null
        };
    });

    return result;
}

return (
    <div>
        <div className="text-center">
            <Button className="btn btn-md px-4" onClick={sendData} color="warning">
                {props.t("Update")}
            </Button>
        </div>
    </div>
);
};

UpdateInslugActions.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
}

export default withTranslation()(UpdateInslugActions)