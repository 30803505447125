import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { getInslugs } from '../../../service/inslugService';
import { Link, useNavigate } from 'react-router-dom'; // Use useNavigate instead of useHistory
import { FaEye } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import DynamicInput from 'components/Common/Form/DynamicInput';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import * as XLSX from 'xlsx'; // Excel export için
import { Button, Input } from 'reactstrap';
import excel from "../../../assets/icons/excel-white.png";
import customStyles from 'styles/customDatatableStyle';


const InslugList = props => {
  const [inslugData, setInslugData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Use useNavigate

  useEffect(() => {
    const fetchInslugData = async () => {
      try {
        const inslugList = await getInslugs();
        console.log("inslugList:", inslugList);
        setInslugData(inslugList);
        setFilteredData(inslugList);
      } catch (error) {
        toast.error("toast-error-fetch-data", { position: "top-right" });
      }
    };
    fetchInslugData();
  }, []);

  const handleViewDetails = (inslug) => {
    navigate(`/inslug-detail/${inslug.id}`);
  };

  useEffect(() => {
    const filtered = inslugData.filter(item =>
      item.client_no?.toLowerCase().includes(searchText.toLowerCase()) ||
      item.company_name?.toLowerCase().includes(searchText.toLowerCase()) ||
      item.booking_date?.toLowerCase().includes(searchText.toLowerCase()) ||
      item.eta?.toLowerCase().includes(searchText.toLowerCase()) ||
      item.closing_out?.toLowerCase().includes(searchText.toLowerCase()) ||
      item.closing_in?.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredData(filtered);
  }, [searchText, inslugData]);

  const columns = [
    { name: props.t("Client No"), selector: row => row.client_no ? row.client_no : "Belirtilmedi", sortable: true },
    { name: props.t("Company Name"), selector: row => row.company_name ? row.company_name : "Belirtilmedi", sortable: true },
    { name: props.t("Booking Date"), selector: row => row.booking_date ? new Date(row.booking_date).toLocaleDateString() : "Belirtilmedi", sortable: true },
    { name: props.t("ETA"), selector: row => row.eta ? new Date(row.eta).toLocaleDateString() : "Belirtilmedi", sortable: true },
    { name: props.t("Closing Out"), selector: row => row.closing_out ? new Date(row.closing_out).toLocaleDateString() : "Belirtilmedi", sortable: true },
    { name: props.t("Closing In"), selector: row => row.closing_in ? new Date(row.closing_in).toLocaleDateString() : "Belirtilmedi", sortable: true },
    {
      name: props.t("Details"),
      cell: row => (
        <FaEye
          size={20}
          color="#CB80AB"
          style={{ cursor: 'pointer' }}
          onClick={() => handleViewDetails(row)} // Handle row click
        />
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const exportToExcel = () => {
    const column1Name = props.t("Client No");
    const column2Name = props.t("Company Name");
    const column3Name = props.t("Booking Date");
    const column4Name = props.t("ETA");
    const column5Name = props.t("Closing In");
    const column6Name = props.t("Closing Out");

    // Sadece 1. ve 2. sütunları (veri) seçiyoruz
    const selectedColumns = inslugData.map(itemInslugData => ({
      [column1Name]: itemInslugData.client_no, // 1. sütun verisi
      [column2Name]: itemInslugData.company_name, // 2. sütun verisi
      [column3Name]: new Date(itemInslugData.booking_date).toLocaleDateString(), // 2. sütun verisi
      [column4Name]: new Date(itemInslugData.eta).toLocaleDateString(), // 2. sütun verisi
      [column5Name]: new Date(itemInslugData.closing_in).toLocaleDateString(), // 2. sütun verisi
      [column6Name]: new Date(itemInslugData.closing_out).toLocaleDateString(), // 2. sütun verisi
    }));

    const worksheet = XLSX.utils.json_to_sheet(selectedColumns);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, props.t("Inslug List"));
    XLSX.writeFile(workbook, props.t("Inslug List")+".xlsx");
  };

  const customPaginationOptions = {
    rowsPerPageText: props.t("Rows count"), // Burada yazıyı özelleştiriyoruz
    rangeSeparatorText: '/',
  };

  return (
    <div className="mt-3">
      <h4 className="text-primary">{props.t("Inslug List")}</h4>

      {/* DataTable */}
      <DataTable
      
        // Arama çubuğunu sağa yaslama
        subHeader
        customStyles={customStyles}
        noDataComponent={props.t("table-empty-message")} // Boş veri mesajını özelleştiriyoruz
        paginationComponentOptions={customPaginationOptions} // Özelleştirilmiş yazı ekledik
        subHeaderComponent={
          <div className='d-flex justify-content-end w-50'>
            <Button className="me-2 p-0 " color="success p-2" onClick={exportToExcel}>
              <img src={excel} width={'24'} alt="" />
            </Button>
            <Input
              type="text"
              placeholder={props.t("Search")}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              className="form-control w-50" // Burada genişliği ve stilini ayarlayabilirsiniz
            />
          </div>
        }
        paginationPerPage={10}  // Her sayfada 5 satır göster
        paginationRowsPerPageOptions={[10]}
        columns={columns}
        data={filteredData}  // Display filtered data
        pagination
        className='p-3'
        highlightOnHover
      />
    </div>
  );
};

InslugList.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation()(InslugList)