import axios from 'axios';
// API URLs
import API_BASE_URL from '../../config'; 
const DEFINITIONS = '/definitions';
const CITY = '/city';
const CREATE = '/create';
const GET = '/get';
const UPDATE = '/update';
const DELETE = '/delete';

/**
 * Yeni şirket oluşturma fonksiyonu
 * @param {Object} data - Şirket oluşturma formunda girilen veriler
 * @returns {Promise} - API cevabı
 */
export const createCity = async (data) => {
  console.log("servis iç");
  console.log(data);
  try {
    const response = await axios.post(`${API_BASE_URL}${DEFINITIONS}${CITY}${CREATE}`, data);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Şehir eklenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirketleri listeleme fonksiyonu
 * @returns {Promise} - API cevabı
 */
export const getCity = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}${DEFINITIONS}${CITY}${GET}`);
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.error('Şehir listelenirken bir hata oluştu:', error);
      throw error;
    }
};

/**
 * Şirket güncelleme fonksiyonu
 * @param {Object} data - Güncellenen şirket bilgileri
 * @returns {Promise} - API cevabı
 */
export const updateCity = async (data) => {
  try {
    console.log(data);
    const response = await axios.post(`${API_BASE_URL}${DEFINITIONS}${CITY}${UPDATE}`, data);
    return response.data;
  } catch (error) {
    console.error('Şehir güncellenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirket silme fonksiyonu
 * @param {string} id - Silinmek istenen şirketin ID'si
 * @returns {Promise} - API cevabı
 */
export const deleteCity = async (id) => {
  try {
    const response = await axios.post(`${API_BASE_URL}${DEFINITIONS}${CITY}${DELETE}`, {id});
    return response.data;
  } catch (error) {
    console.error('Şehir silinirken bir hata oluştu:', error);
    throw error;
  }
};