import { SET_SELECTED_UPDATE_CLIENT_PRODUCT, RESET_SELECTED_UPDATE_CLIENT_PRODUCTS } from './actionTypes';

const initialState = {
  selectedUpdateClientProducts: [],
};

const selectedUpdateClientProductsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_UPDATE_CLIENT_PRODUCT:
      return {
        ...state,
        selectedClientProducts: action.payload,
      };

    case RESET_SELECTED_UPDATE_CLIENT_PRODUCTS:
      return initialState; 

    default:
      return state;
  }
};

export default selectedUpdateClientProductsReducer;
