import React, { useState, useEffect } from 'react';
import { Button, Form, FormGroup, Label, Input, Container, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { createCompany, createExternCompany, getCompanies, getExternCompanies } from '../../service/companyService';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { updateCompanyAction, deleteCompanyAction, setCompanies } from '../../store/company/actions'; // Redux actions
import DynamicSelect from 'components/Common/Form/DynamicSelect';
import { setExternCompanies } from 'store/externCompany/actions';
import DynamicInput from 'components/Common/Form/DynamicInput';
import { openModal, closeModal } from '../../store/modal/actions'; // Modal actions
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import AcceptModal from 'components/Common/Modals/acceptModal';
import PhoneInputWithCountrySelect from 'react-phone-number-input';
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import '../../styles/custom/phone-style.css'
import { createCountry, getCountries } from 'service/Definitions/countryService';
import DynamicCreatableSelect from 'components/Common/Form/DynamicCreatableSelect';
import { getCity } from 'service/Definitions/cityService';


const CompanyForm = props => {
    const dispatch = useDispatch();

    const [formData, setFormData] = useState({});
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState([]);
    const [cities, setCities] = useState([]);
    const [selectedCity, setSelectedCity] = useState([]);

    const [isExtern, setIsExtern] = useState(); // Modalın açılma durumunu yönetiyoruz
    const [isHovered, setIsHovered] = useState(false); // Hover durumu için state
    const MAX_LENGTH = 10;  // Telefon numarası için karakter sınırı


    useEffect(() => {
        const fetchData = async () => {
            const countryList = await getCountries();
            const cityList = await getCity();
            console.log(countryList);
            console.log(cities);
            const formattedCities = cityList.map(city => ({
                value: city.id,
                label: city.name,
                country_id: city.country_id
              }));

              const formattedCountries = countryList.map(country => ({
                value: country.id,
                label: country.name
              }));
              console.log("**********");
              console.log(formattedCities);
              console.log(formattedCountries);
            setCountries(formattedCountries);
            setCities(formattedCities);
        }

        fetchData();
    }, [dispatch]);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value.toLocaleUpperCase("tr"),
        });
    };

    const handleChangeLowerCase = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handlePhone = (value) => {
        console.log("value");
        console.log(value);
        setFormData({
            ...formData,
            ["phone"]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        handleConfirm()
    };

    const handleSelectCountryChange = (field, selected) => {
        console.log(selected);
        setSelectedCountry(selected)
        setFormData({ ...formData, [field]: selected ? selected.value : null});
    };

    const handleSelectCityChange = (field, selected) => {
        setSelectedCity(selected)
        setFormData({ ...formData, [field]: selected?.value });
    };

    const handleCreateCompany = (inputValue) => {
        dispatch(
            openModal({
                title: props.t("modal-save-title"),
                body: <span>{props.t("modal-save-body")}</span>,
                onConfirm: async () => {
                    // Yeni bir şirket oluşturmak için:
                    const newCountry = {
                        name: inputValue,  // Kullanıcının girdiği değer
                    };

                    await createCountry(newCountry)
                        .then(async (id) => {
                            const newCountryList = await getCountries();// Yeni şirketleri yeniden getir
                            const newSelected = newCountryList.find(data => data.id === id);
                            const formattedCountries = newCountryList.map(country => ({
                                value: country.id,
                                label: country.name
                              }));
                            setCountries(formattedCountries);
                            setSelectedCountry({ value: newSelected.id, label: newSelected.name });
                            setFormData({ ...formData, ["id"]: newSelected.id });
                            toast.success(props.t("toast-success-save-data"), { position: "top-right" });
                        })
                        .catch((error) => {
                            toast.error(props.t("toast-error-save-data"), { position: "top-right" });
                        }); dispatch(closeModal());
                }
            }));// Modalı açıyoruz
    }

    const handleConfirm = async () => {
        dispatch(
            openModal({
                title: props.t("modal-update-title"),
                body: props.t("modal-update-body"),
                onConfirm: async () => {
                    if (isExtern.value == true) {
                        await createCompany(formData)
                            .then(async () => {
                                toast.success(props.t("toast-success-update-data"), { position: "top-right" });
                                console.log(formData);
                                const updatedCompanies = await getCompanies(); // Fetch updated companies
                                dispatch(setCompanies(updatedCompanies)); // Update Redux state
                                setSelectedCountry(null);
                                setSelectedCity(null);
                                setFormData({});
                            })
                            .catch((error) => {
                                console.error("Şirket ekelme işlemi başarısız oldu:", error);
                                toast.error(props.t("toast-error-update-data"), { position: "top-right" });
                            });

                        dispatch(closeModal()); // Close the modal after failure
                    } else {
                        await createExternCompany(formData)
                            .then(async () => {
                                toast.success(props.t("toast-success-update-data"), { position: "top-right" });
                                console.log(formData);
                                const updatedCompanies = await getExternCompanies(); // Fetch updated companies
                                dispatch(setExternCompanies(updatedCompanies)); // Update Redux state

                                setFormData({});
                            })
                            .catch((error) => {
                                console.error("Şirket eklenemedi:", error);
                                toast.error(props.t("toast-error-update-data"), { position: "top-right" });
                            });

                        dispatch(closeModal()); // Close the modal after failure
                    }
                }
            })
        );


    };

    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <div className='d-flex justify-content-between mb-4'>
                    <h4 className="text-info">{props.t("Add Company")}</h4>
                    <button className='btn btn-md bg-success text-white' color="primary" type="submit">
                        {props.t("Save")}
                    </button>
                </div>
                <Row className='mb-4' form>
                    <Col md={3}>
                        <FormGroup>
                            <Label for="client_no">{props.t("Client Number")}</Label>
                            <DynamicInput
                                type="text"
                                name="client_no"
                                id="client_no"
                                value={formData.client_no}
                                onChange={handleChange}
                                placeholder={props.t("Enter client number")}
                                required
                            />
                        </FormGroup>
                    </Col>

                    <Col md={3}>
                        <FormGroup>
                            <Label for="name">{props.t("Company Name")}</Label>
                            <DynamicInput
                                type="text"
                                name="name"
                                id="name"
                                value={formData.name}
                                onChange={handleChange}
                                placeholder={props.t("Enter company name")}
                                required
                            />
                        </FormGroup>
                    </Col>

                    <Col md={3}>
                        <FormGroup>
                            <Label for="email">{props.t("Mail")}</Label>
                            <DynamicInput
                                type="email"
                                name="email"
                                id="email"
                                value={formData.email}
                                onChange={handleChangeLowerCase}
                                placeholder={props.t("Enter mail address")}
                            />
                        </FormGroup>
                    </Col>

                    <Col md={3}>
                        <FormGroup>
                            <Label for="phone">{props.t("Phone")}</Label>
                            <PhoneInput
                                className='custom-phone-input bg-white fs-6'
                                placeholder="Enter phone number"
                                value={formData.phone}
                                
                                onMouseEnter={() => setIsHovered(true)}  // Hover durumu aktif
                                onMouseLeave={() => setIsHovered(false)} // Hover durumu pasif
                                onChange={value => handlePhone(value)} />
                        </FormGroup>
                    </Col>

                    <Col md={3}>
                        <FormGroup>
                            <Label for="address">{props.t("Address")}</Label>
                            <DynamicInput
                                type="text"
                                name="address"
                                id="address"
                                value={formData.address}
                                onChange={handleChange}
                                placeholder={props.t("Enter address")}
                            />
                        </FormGroup>
                    </Col>

                    <Col md={3}>
                        <FormGroup>
                            <Label for="country">{props.t("Country")}</Label>
                            {/* <DynamicInput
                                type="text"
                                name="country"
                                id="country"
                                value={formData.country}
                                onChange={handleChange}
                                placeholder={props.t("Enter country")}
                            /> */}
                            <DynamicCreatableSelect
                                options={countries}
                                value={selectedCountry}
                                onChange={(selected) => handleSelectCountryChange("country_id", selected)} // Kategori seçimi
                                onCreateOption={async (inputValue) => { handleCreateCompany(inputValue) }}
                                placeholder={props.t("Enter country")}
                                isClearable
                                noOptionsMessage={() => props.t("no-option-message")}
                                formatCreateLabel={(inputValue) => `${props.t("Add")}: ${inputValue}`} // Yeni seçenek ekleme mesajı
                                onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                                filterOption={(option, inputValue) => {
                                    const clientNo = option.data.label || "";
                                    // Hem client_no hem de company.name üzerinden arama yapabilmek için
                                    const companyNameMatch = clientNo.toLowerCase().includes(inputValue.toLowerCase());

                                    return companyNameMatch;
                                }}
                            />
                        </FormGroup>
                    </Col>

                    <Col md={3}>
                        <FormGroup>
                            <Label for="city">{props.t("City")}</Label>
                            <DynamicSelect
                                options={cities.filter(data => selectedCountry ? data.country_id === selectedCountry.value : null)} // Filtreleme işlemi
                                value={selectedCity}
                                onChange={(selected) => handleSelectCityChange("city_id", selected)} // Kategori seçimi
                                placeholder={props.t("Enter city")}
                                isClearable
                                noOptionsMessage={() => props.t("no-option-message")}
                                onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                                filterOption={(option, inputValue) => {
                                    // Hem client_no hem de company.name üzerinden arama yapabilmek için
                                    const clientNo = option.data.label || "";

                                    const companyNameMatch = clientNo.toLowerCase().includes(inputValue.toLowerCase());

                                    return companyNameMatch;
                                }}
                            />
                        </FormGroup>
                    </Col>

                    <Col md={3}>
                        <FormGroup>
                            <Label for="iban">{props.t("IBAN")}</Label>
                            <DynamicInput
                                type="text"
                                name="iban"
                                id="iban"
                                value={formData.iban}
                                onChange={handleChange}
                                placeholder={props.t("Enter IBAN")}
                            />
                        </FormGroup>
                    </Col>

                    <Col md={3}>
                        <FormGroup>
                            <Label for="tax_no">{props.t("Tax Number")}</Label>
                            <DynamicInput
                                type="text"
                                name="tax_no"
                                id="tax_no"
                                value={formData.tax_no}
                                onChange={handleChange}
                                placeholder={props.t("Enter tax number")}
                            />
                        </FormGroup>
                    </Col>

                    <Col md={3}>
                        <FormGroup>
                            <Label for="tax_office">{props.t("Tax Office")}</Label>
                            <DynamicInput
                                type="text"
                                name="tax_office"
                                id="tax_office"
                                value={formData.tax_office}
                                onChange={handleChange}
                                placeholder={props.t("Enter tax office")}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <FormGroup>
                            <Label for="is_extern_company">{props.t("Company Type")}</Label>
                            <DynamicSelect
                                options={[
                                    { value: true, label: props.t("Company") },  // true için seçenek
                                    { value: false, label: props.t("Extern Company") }   // false için seçenek
                                ]}
                                value={isExtern}
                                noOptionsMessage={() => props.t("no-option-message")}
                                onChange={(selected) => {
                                    console.log(selected);
                                    setIsExtern(selected);
                                }}
                                placeholder={props.t("Select company type")}
                                isClearable
                                required
                            />
                        </FormGroup>
                    </Col>
                </Row>


            </Form>



            <AcceptModal />
        </div>
    );
};

CompanyForm.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
}

export default withTranslation()(CompanyForm)