import React, { useState } from 'react';
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
import { Alert, Card, CardBody, Col, Container, Input, Label, Row, FormFeedback } from 'reactstrap';
import axios from 'axios'; // Axios kullanımı
import { useFormik } from 'formik';
import * as Yup from 'yup';
import logo from "../../assets/images/act-logo.webp";
import withRouter from 'components/Common/withRouter';
import { withTranslation } from "react-i18next"
import LanguageDropdown from 'components/CommonForBoth/TopbarDropdown/LanguageDropdown';


const ChangePasswordPage = (props) => {
  document.title = props.t("change-password-title");;

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(prevState => !prevState); // Şifreyi göster/gizle
  };
  const { router: { navigate } } = props;

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validationSchema: Yup.object({
      password: Yup.string().required(props.t("Please enter your new password")),
      confirmPassword: Yup.string()
        .required(props.t("Please confirm your password"))
        .oneOf([Yup.ref('password'), null], props.t("Passwords must match")),
    }),
    onSubmit: async (values) => {
      const token = new URLSearchParams(window.location.search).get('token'); // URL'den token al
      if (token) {
        try {
          const response = await axios.post('https://back.actnatie.com/api/users/change-password', {
            newPassword: values.password,
            token
          });
          if (response.data.success) {
            setSuccess(props.t("Password changed successfully. Please log in."));
            setError('');
            setTimeout(() => {
              navigate('/login'); // Başarılı olduktan sonra 2 saniye sonra yönlendir
            }, 2000);
          } else {
            setError(props.t("There was an error during the password change process."));
            setSuccess('');
          }
        } catch (error) {
          setError(props.t("There was an error during the password change process."));
          setSuccess('');
        }
      } else {
        setError(props.t("Token is invalid or missing"));
        setSuccess('');
      }
    }
  });

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <h3 className="text-center mt-5 mb-4">
                    <Link to="/" className="d-block auth-logo">
                      <img src={logo} alt="" className="auth-logo-dark" />
                    </Link>
                  </h3>
                  <div className="p-3">
                    <h4 className="text-muted font-size-18 mb-3 text-center">{props.t("Change Password")}</h4>
                    {error && (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {error}
                      </Alert>
                    )}
                    {success && (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {success}
                      </Alert>
                    )}
                    <form className="form-horizontal mt-4" onSubmit={validation.handleSubmit}>
                      <div className="mb-3">
                        <Label htmlFor="password">{props.t("New Password")}</Label>
                        <Input
                          name="password"
                          className="form-control"
                          placeholder={props.t("New Password")}
                          type={showPassword ? 'text' : 'password'}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password}
                          invalid={
                            validation.touched.password && validation.errors.password ? true : false
                          }
                        />
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label htmlFor="confirmPassword">{props.t("Confirm Password")}</Label>
                        <Input
                          name="confirmPassword"
                          className="form-control"
                          placeholder={props.t("Confirm Password")}
                          type={showPassword ? 'text' : 'password'}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.confirmPassword}
                          invalid={
                            validation.touched.confirmPassword && validation.errors.confirmPassword ? true : false
                          }
                        />
                        <span
                            onClick={togglePasswordVisibility}
                            style={{
                              marginLeft:"10px",
                              cursor: 'pointer',
                              fontSize: '18px'

                            }}
                          >

                            <i className={`mdi ${showPassword ? 'mdi-eye-off' : 'mdi-eye'}`}></i>
                          </span>
                        {validation.touched.confirmPassword && validation.errors.confirmPassword ? (
                          <FormFeedback type="invalid">{validation.errors.confirmPassword}</FormFeedback>
                        ) : null}
                      </div>
                      <Row className="mb-3">
                        <div className="col-12 text-end">
                          <button className="btn btn-primary w-md waves-effect waves-light" type="submit">{props.t("Change Password")}</button>
                        </div>
                      </Row>
                    </form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <div className="mt-5 text-center">
          <Link to="/login" className="text-primary"> {props.t("Login")}</Link>
        </div>
        <div className='text-center'>
          <LanguageDropdown />
        </div>
      </div>
    </React.Fragment>
  );
};

ChangePasswordPage.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  router: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(ChangePasswordPage))