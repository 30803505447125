import React, { useState, useEffect } from 'react';
import { Input, Button, Form, FormGroup, Label, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { createProduct, getProducts } from '../../service/productService'; // Product ve Kategori servisi
import { createClientProduct, getClientProducts } from '../../service/clientProductService'; // Product ve Kategori servisi

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { openModal, closeModal } from '../../store/modal/actions'; // Modal actions
import DynamicSelect from 'components/Common/Form/DynamicSelect';
import DynamicInput from 'components/Common/Form/DynamicInput';
import AcceptModal from 'components/Common/Modals/acceptModal';
import { createCompany, getCompanies } from 'service/companyService';
import DynamicCreatableSelect from 'components/Common/Form/DynamicCreatableSelect';
import { setClientProducts } from 'store/clientProduct/action';
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import { createCountry, getCountries } from 'service/Definitions/countryService';

const ClientProductForm = props => {
  const dispatch = useDispatch();

  // Form state, JSON yapısına göre düzenlendi
  const [formData, setFormData] = useState({});

  const [products, setProducts] = useState([]); // Kategorileri tutacak state
  const [selectedProduct, setSelectedProduct] = useState(null); // Seçilen kategoriyi tutacak state
  const [companies, setCompanies] = useState([]); // Kategorileri tutacak state
  const [selectedCompany, setSelectedCompany] = useState(null); // Seçilen kategoriyi tutacak state
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
        const countryList = await getCountries();
          const formattedCountries = countryList.map(country => ({
            value: country.id,
            label: country.name
          }));
          console.log("**********");
          console.log(formattedCountries);
        setCountries(formattedCountries);
    }

    fetchData();
}, [dispatch]);

  // Kategori verilerini çekmek için useEffect
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const productList = await getProducts(); // Kategorileri çekiyoruz
        setProducts(productList); // Kategorileri state'e set ediyoruz
        const companyList = await getCompanies(); // Kategorileri çekiyoruz
        setCompanies(companyList); // Kategorileri state'e set ediyoruz
      } catch (error) {
        toast.error(props.t("toast-error-fetch-data"), { position: "top-right" });
      }
    };
    fetchCategories();
  }, []);

  const handleChange = (e) => {
    const upperCaseValue = e.target.value.toLocaleUpperCase("tr");
    setFormData({
      ...formData,
      [e.target.name]: upperCaseValue,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(openModal(
      {
        title: props.t("modal-save-title"),
        body: props.t("modal-save-body"),
        onConfirm: async () => {
          handleConfirm();
        }
      }
    )); // Modalı açıyoruz
  };

  const handleSelectProductChange = (field, selected) => {
    setSelectedProduct(selected)
    if (selected) {
      setFormData({ ...formData, [field]: selected.value });
    }
  };

  const handleSelectCompanyChange = (field, selected) => {
    console.log("field111");
    console.log(field);
    console.log("selected111");
    console.log(selected);
    setSelectedCompany(selected)
    setFormData({ ...formData, [field]: selected?.value });
  };

  const handleSelectCountryChange = (field, selected) => {
    console.log(selected);
    setSelectedCountry(selected)
    setFormData({ ...formData, [field]: selected ? selected.value : null});
};

const handleCreateCompany = (inputValue) => {
  dispatch(
      openModal({
          title: props.t("modal-save-title"),
          body: <span>{props.t("modal-save-body")}</span>,
          onConfirm: async () => {
              // Yeni bir şirket oluşturmak için:
              const newCountry = {
                  name: inputValue,  // Kullanıcının girdiği değer
              };

              await createCountry(newCountry)
                  .then(async (id) => {
                      const newCountryList = await getCountries();// Yeni şirketleri yeniden getir
                      const newSelected = newCountryList.find(data => data.id === id);
                      const formattedCountries = newCountryList.map(country => ({
                          value: country.id,
                          label: country.name
                        }));
                      setCountries(formattedCountries);
                      setSelectedCountry({ value: newSelected.id, label: newSelected.name });
                      setFormData({ ...formData, ["id"]: newSelected.id });
                      toast.success(props.t("toast-success-save-data"), { position: "top-right" });
                  })
                  .catch((error) => {
                      toast.error(props.t("toast-error-save-data"), { position: "top-right" });
                  }); dispatch(closeModal());
          }
      }));// Modalı açıyoruz
}

  useEffect(() => {
    console.log("Formdata güncellendi");
    console.log(formData);
  }, [formData])

  useEffect(() => {
    console.log("selectedCategory güncellendi");
    console.log(selectedProduct);
  }, [selectedProduct])

  const handleConfirm = async () => {
    const finalData = { ...formData };
    await createClientProduct(finalData)
      .then(async () => {
        toast.success(props.t("toast-success-save-data"), { position: "top-right" });
        const updatedProducts = await getClientProducts();
        dispatch(setClientProducts(updatedProducts));
        setSelectedCompany(null);
        setSelectedCountry(null);
        setSelectedProduct(null);
        setFormData({});
      })
      .catch((error) => {
        console.error("Müşteri ürünü eklenemedi:", error);
        toast.error(props.t("toast-error-save-data"), { position: "top-right" });
      });
    dispatch(closeModal()); // Modalı kapatır
  };

  const handleCreateCountry = (inputValue) => {
    dispatch(
      openModal({
        title: props.t("modal-save-title"),
        body: <span>{props.t("modal-save-body")}</span>,
        onConfirm: async () => {
          // Yeni bir şirket oluşturmak için:
          const newCompany = {
            client_no: Math.random().toString(36).substr(2, 8).toUpperCase(), // Rastgele 8 karakterli müşteri numarası
            name: inputValue,  // Kullanıcının girdiği değer
          };

          await createCompany(newCompany)
            .then(async () => {
              const newCompanyList = await getCompanies();// Yeni şirketleri yeniden getir

              // Yeni şirketi listeye ekle
              setCountries(newCompanyList);
              setSelectedCountry({ value: newCompany.client_no, label: newCompany.name });
              setFormData({ ...formData, ["client_no"]: newCompany.client_no });
              toast.success(props.t("toast-success-save-data"), { position: "top-right" });

              // Yeni şirketi otomatik olarak seç
              /*     const selectedItem = { value: newCompany.client_no, label: newCompany.name };
                  setSelectedCompany({ supplier_company_no: newCompany.client_no }); */
            })
            .catch((error) => {
              toast.error(props.t("toast-error-save-data"), { position: "top-right" });
            }); dispatch(closeModal());
        }
      }));// Modalı açıyoruz
  }

  const handleCreateProduct = (inputValue) => {
    dispatch(
      openModal({
        title: props.t("modal-save-title"),
        body: <span> {props.t("modal-save-body")}</span>,
        onConfirm: async () => {
          // Yeni bir şirket oluşturmak için:
          const newProduct = {
            product_no: Math.random().toString(36).substr(2, 8).toUpperCase(), // Rastgele 8 karakterli müşteri numarası
            name: inputValue,  // Kullanıcının girdiği değer
          };

          await createProduct(newProduct)
            .then(async () => {
              const newProductList = await getCompanies();// Yeni şirketleri yeniden getir

              // Yeni şirketi listeye ekle
              setProducts(newProductList);
              setSelectedProduct({ value: newProduct.product_no, label: newProduct.name });
              toast.success(props.t("toast-success-save-data"), { position: "top-right" });
            })
            .catch((error) => {
              toast.error(props.t("toast-error-save-data"), { position: "top-right" });
            }); dispatch(closeModal());
        }
      }));// Modalı açıyoruz
  }

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <div className='d-flex justify-content-between mb-4'>
          <h4 className="text-primary">{props.t("Add Client Product")}</h4>
          <button className='btn btn-md bg-success text-white' color="primary" type="submit">
            {props.t("Save")}
          </button>
        </div>
        <Row className='mb-4' form>
          <Col md={3}>
            <FormGroup>
              <Label for="client_no">{props.t("Company Information")}</Label>
              <DynamicCreatableSelect
                options={companies.map((company) => ({
                  value: company.client_no,  // value'da client_no kullanıyoruz
                  label: company.name,  // label'da company.name kullanıyoruz
                }))}
                value={selectedCompany}
                onChange={(selected) => handleSelectCompanyChange("client_no", selected)} // Kategori seçimi
                onCreateOption={async (inputValue) => { handleCreateCompany(inputValue) }}
                placeholder={props.t("Select company")}
                isClearable
                required
                noOptionsMessage={() => props.t("no-option-message")}
                formatCreateLabel={(inputValue) => `${props.t("Add")}: ${inputValue}`} // Yeni seçenek ekleme mesajı
                onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                filterOption={(option, inputValue) => {
                  // Hem client_no hem de company.name üzerinden arama yapabilmek için
                  const clientNoMatch = option.value.toLowerCase().includes(inputValue.toLowerCase());
                  const companyNameMatch = option.label.toLowerCase().includes(inputValue.toLowerCase());

                  return clientNoMatch || companyNameMatch;
                }}
              />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="product_no">{props.t("Product Information")}</Label>
              <DynamicSelect
                options={products.map((product) => ({
                  value: product.product_no,  // value'da client_no kullanıyoruz
                  label: product.name,  // label'da company.name kullanıyoruz
                }))}
                value={selectedProduct}
                onChange={(selected) => handleSelectProductChange("product_no", selected)} // Kategori seçimi
                onCreateOption={async (inputValue) => { handleCreateProduct(inputValue.toLocaleUpperCase("tr")) }}
                placeholder={props.t("Select product")}
                isClearable
                required
                noOptionsMessage={() => props.t("no-option-message")}
                onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                formatCreateLabel={(inputValue) => `${props.t("Add")}: ${inputValue.toLocaleUpperCase("tr")}`} // Yeni seçenek ekleme mesajı
                filterOption={(option, inputValue) => {
                  // Hem client_no hem de company.name üzerinden arama yapabilmek için
                  const clientNoMatch = option.value.toLowerCase().includes(inputValue.toLowerCase());
                  const companyNameMatch = option.label.toLowerCase().includes(inputValue.toLowerCase());

                  return clientNoMatch || companyNameMatch;
                }}
              />
            </FormGroup>
          </Col>

          <Col md={3}>
            <FormGroup>
              <Label for="extern_no">{props.t("Extern No")}</Label>
              <DynamicInput
                type="text"
                name="extern_no"
                id="extern_no"
                value={formData.extern_no}
                onChange={handleChange}
                placeholder={props.t("Enter extern no")}
              />
            </FormGroup>
          </Col>

          <Col md={3}>
            <FormGroup>
              <Label for="origin_country">{props.t("Origin Country")}</Label>
              {/* <DynamicInput
                type="text"
                name="origin_country"
                id="origin_country"
                value={formData.origin_country}
                onChange={handleChange}
                placeholder={props.t("Enter origin country")}
              /> */}
              <DynamicCreatableSelect
                options={countries}
                value={selectedCountry}
                onChange={(selected) => handleSelectCountryChange("country_id", selected)} // Kategori seçimi
                onCreateOption={async (inputValue) => { handleCreateCountry(inputValue) }}
                placeholder={props.t("Enter country")}
                isClearable
                required
                noOptionsMessage={() => props.t("no-option-message")}
                formatCreateLabel={(inputValue) => `${props.t("Add")}: ${inputValue}`} // Yeni seçenek ekleme mesajı
                onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                filterOption={(option, inputValue) => {
                  const clientNo = option.data.label || "";
                  // Hem client_no hem de company.name üzerinden arama yapabilmek için
                  const companyNameMatch = clientNo.toLowerCase().includes(inputValue.toLowerCase());

                  return companyNameMatch;
                }}
              />
            </FormGroup>
          </Col>

          <Col md={3}>
            <FormGroup>
              <Label for="production_date">{props.t("Production Date")}</Label>
              <DynamicInput
                type="date"
                id="production_date"
                name="production_date"
                value={formData.production_date}
                onChange={handleChange}
                placeholderText={props.t("Enter production date")}
              />
            </FormGroup>
          </Col>
        </Row>
      </Form>

      <AcceptModal />
    </div>
  );
};

ClientProductForm.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation()(ClientProductForm)