import React from 'react';
import CreatableSelect from 'react-select/creatable';

// Dinamik React Creatable Select fonksiyonu
const DynamicCreatableSelect = ({
  name = '',
  value = null,
  options = [],
  onChange = () => {},
  onCreateOption = () => {},  // Yeni bir seçenek eklemek için kullanılan fonksiyon
  placeholder = 'Lütfen bir seçenek seçin',
  formatCreateLabel = (inputValue) => `Yeni Ekle: ${inputValue}`,  // Yeni ürün eklerken kullanılan etiket
  isSearchable = true,
  isClearable = true,
  required = false,
  menuPortalTarget = document.body,  // Menünün nereye render edileceği
  menuShouldScrollIntoView = false,  // Menü içinde scroll yapılabilsin
  filterOption = null,
  onInputChange = null,
  noOptionsMessage = () => "No record found",
  styles = {
    control: (base, state) => ({
      ...base,
      backgroundColor: 'transparent',
      border: '2px solid #E1AFD1',  // Sabit mavi border
      boxShadow: 'none',  // Focus durumunda gölge eklenmeyecek
      '&:hover': { borderColor: '#7469B6' },  // Hover durumunda da mavi kalacak
      padding: "8px",
      borderRadius: '8px',
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),  // Menü portalını en üste getir
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected 
      ? '#007bff' // Seçiliyse mavi
      : state.isFocused 
      ? '#deecff' // Üzerine gelindiğinde açık mavi
      : '#fff', // Normalde beyaz
      //backgroundColor: state.isSelected ? '#007bff' : '#fff',  // Seçili olduğunda arka plan rengi
      color: state.isSelected ? '#fff' : '#333',  // Seçili olduğunda yazı rengi
    }),
    menu: (provided) => ({
      ...provided,
      left: 0,               // Ekranın sol kenarına hizalar
      right: 0,              // Ekranın sağ kenarına hizalar
      zIndex: 9999,          // Menü diğer içeriklerin üzerinde kalır
      overflow: 'hidden',    // Taşmayı engeller
    }),
    indicatorSeparator: () => ({ display: 'none' }),  // Yan dik çizgiyi kaldır
    dropdownIndicator: (base) => ({
      ...base,
    }),
    input: (base) => ({
      ...base,
      margin: 0,
      padding: 0,
    }),
    placeholder: (base) => ({
      ...base,
      color: '#999',
    }),
  },
  className = 'shadow bg-white fs-6',
}) => {
  return (
    <CreatableSelect
      noOptionsMessage={noOptionsMessage}
      name={name}
      value={value}
      options={options}
      onChange={onChange}
      onCreateOption={onCreateOption}  // Yeni bir ürün ekleme fonksiyonu
      placeholder={placeholder}
      formatCreateLabel={formatCreateLabel}  // Yeni ürün ekleme etiketi
      isSearchable={isSearchable}
      isClearable={isClearable}
      menuPortalTarget={menuPortalTarget}  // Menü body'ye render edilecek
      menuShouldScrollIntoView={menuShouldScrollIntoView}
      styles={styles}
      className={className}
      filterOption={filterOption}
      onInputChange={onInputChange}
      required={required}
    />
  );
};

export default DynamicCreatableSelect;
