// inslugItem/reducer.js

import { ADD_UPDATE_INSLUG_ITEM_ROW, SET_UPDATE_INSLUG_ITEM_DATA, RESET_UPDATE_INSLUG_ITEMS, REMOVE_UPDATE_LAST_INSLUG_ITEM_ROW, SET_UPDATE_INSLUG_ITEMS_ALL } from './actionTypes';

const initialState = {
  updateInslugItems: [{}],
};

const updateInslugItemReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_UPDATE_INSLUG_ITEM_ROW:
      return {
        ...state,
        updateInslugItems: [...state.updateInslugItems, action.payload],
      };

    case SET_UPDATE_INSLUG_ITEM_DATA:
      const updatedInslugItems = state.updateInslugItems.map((item, index) => {
        if (index === action.payload.index) {
          return {
            ...item,
            [action.payload.field]: action.payload.value,
          };
        }
        return item;
      });
      return {
        ...state,
        updateInslugItems: updatedInslugItems,
      };

    case RESET_UPDATE_INSLUG_ITEMS:
      return {
        ...state,
        updateInslugItems: initialState.updateInslugItems,
      };

    case SET_UPDATE_INSLUG_ITEMS_ALL:
      return {
        ...state,
        updateInslugItems: action.payload,
      };

    case REMOVE_UPDATE_LAST_INSLUG_ITEM_ROW:
      if (state.updateInslugItems.length > 1) { // Eğer sadece 1 satır varsa, onu silme
        return {
          ...state,
          updateInslugItems: state.updateInslugItems.slice(0, -1), // Son elemanı kaldır
        };
      }
      return state; // Eğer tek bir satır varsa, bir işlem yapma

    default:
      return state;
  }
};

export default updateInslugItemReducer;
