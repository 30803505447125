import React, { useState, useEffect } from 'react';
import { Input, Button, Form, FormGroup, Label, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { createProduct, getProductCategory, getProducts } from '../../service/productService'; // Product ve Kategori servisi
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { setProducts } from '../../store/product/action'; // Redux actions
import { openModal, closeModal } from '../../store/modal/actions'; // Modal actions
import DynamicSelect from 'components/Common/Form/DynamicSelect';
import DynamicInput from 'components/Common/Form/DynamicInput';
import AcceptModal from 'components/Common/Modals/acceptModal';
import DynamicCreatableSelect from 'components/Common/Form/DynamicCreatableSelect';
import { createProductGroup, getProductGroup } from 'service/productGroupService';
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"


const ProductForm = props => {
  const dispatch = useDispatch();

  // Form state, JSON yapısına göre düzenlendi
  const [formData, setFormData] = useState({});

  const [productGroups, setProductGroups] = useState([]); // Kategorileri tutacak state
  const [selectedProductGroup, setSelectedProductGroup] = useState(null); // Seçilen kategoriyi tutacak state

  // Kategori verilerini çekmek için useEffect
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const productGroupList = await getProductCategory(); // Kategorileri çekiyoruz
        console.log("productCategoryList");
        console.log(productGroupList);
        setProductGroups(productGroupList); // Kategorileri state'e set ediyoruz
      } catch (error) {
        toast.error("Kategoriler getirilirken bir hata oluştu.", { position: "top-right" });
      }
    };
    fetchCategories();
  }, []);

  const handleChange = (e) => {
    const upperCaseValue = e.target.value.toLocaleUpperCase("tr");
    setFormData({
      ...formData,
      [e.target.name]: upperCaseValue,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(openModal(
      {
        title: props.t("Add Product"),
        body: props.t("Are you sure you want to add the product?"),
        confirmLabel: props.t("Add"),
        cancelLabel: props.t("Cancel"),
        onConfirm: async () => {
          handleConfirm();
        }
      }
    )); // Modalı açıyoruz
  };

  const handleSelectChange = (field, selected) => {
    setSelectedProductGroup(selected)
    if (selected) {
      setFormData({ ...formData, [field]: selected.value });
    }
  };

  useEffect(() => {
    console.log("Formdata güncellendi");
    console.log(formData);
  }, [formData])

  useEffect(() => {
    console.log("selectedCategory güncellendi");
    console.log(selectedProductGroup);
  }, [selectedProductGroup])

  const createUid = () => {
    return Math.random().toString(36).substr(2, 8).toUpperCase();
  };

  const handleConfirm = async () => {
    const uid = createUid();
    const finalData = { ...formData, product_no: uid };
    await createProduct(finalData)
      .then(async () => {
        toast.success(props.t("Product added successfully!"), { position: "top-right" });
        const updatedProducts = await getProducts(); // Ürünleri güncelle
        dispatch(setProducts(updatedProducts)); // Redux state güncelleme
        setSelectedProductGroup(null);
        setFormData({
          product_no: '',
          product_category_no: '',
          name: '',
          description: '',
          gtip_code: '',
          cost: '',
          document_attribute_id: '',
          additional_attribute: '',
          is_deleted: false,
        });
      })
      .catch((error) => {
        console.error("Ürün eklenemedi:", error);
        toast.error(props.t("Product addition failed."), { position: "top-right" });
      });
    dispatch(closeModal()); // Modalı kapatır
  };

  const handleCreateProductGroup = async (name) => {
    dispatch(
      openModal({
        title: props.t("Add Product Group"),
        body: <span>{props.t("Are you sure you want to add a new product group?")}</span>,
        confirmLabel: props.t("Add"),
        cancelLabel: props.t("Cancel"),
        onConfirm: async () => {
          const newProductCategoryNo = Math.random().toString(36).substr(2, 8).toUpperCase()
          const data = { product_category_no: newProductCategoryNo, name: name };
          await createProductGroup(data)
            .then(async () => {
              toast.success(props.t("Product group added successfully!"), { position: "top-right" });
              const updatedProducts = await getProductGroup(); // Ürünleri güncelle
              setProductGroups(updatedProducts);
              console.log("dattaaaaaaaaa");
              console.log(data);
              const newData = { value: data.product_category_no, label: data.name };
              handleSelectChange("product_category_no", newData);
              /*               setSelectedProductGroup({
                              value: data.product_category_no,
                              label: data.name,
                            }); */
            })
            .catch((error) => {
              console.error("Ürün grubu eklenemedi:", error);
              toast.error(props.t("Product group addition failed."), { position: "top-right" });
            });
          dispatch(closeModal());
        }
      }));// Modalı açıyoruz
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <div className='d-flex justify-content-between mb-4'>
          <h4 className="text-primary">{props.t("Add Product")}</h4>
          <button className='btn btn-md bg-success text-white' color="primary" type="submit">
            {props.t("Save")}
          </button>
        </div>
        <Row className='mb-4' form>
          <Col md={3}>
            <FormGroup>
              <Label for="product_category_no">{props.t("Product Category")}</Label>
              <DynamicCreatableSelect
                options={productGroups.map((category) => ({
                  value: category.product_category_no,  // value'da client_no kullanıyoruz
                  label: category.name,  // label'da company.name kullanıyoruz
                }))}
                value={selectedProductGroup ? selectedProductGroup : null}
                onChange={(selected) => handleSelectChange("product_category_no", selected)} // Kategori seçimi
                onCreateOption={async (inputValue) => { handleCreateProductGroup(inputValue) }}
                placeholder={props.t("Select product group")}
                isClearable
                required
                noOptionsMessage={() => props.t("no-option-message")}
                formatCreateLabel={(inputValue) => `${props.t("Add")}: ${inputValue}`} // Yeni seçenek ekleme mesajı
                onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                filterOption={(option, inputValue) => {
                  // Hem client_no hem de company.name üzerinden arama yapabilmek için
                  const clientNoMatch = option.value.toLowerCase().includes(inputValue.toLowerCase());
                  const companyNameMatch = option.label.toLowerCase().includes(inputValue.toLowerCase());

                  return clientNoMatch || companyNameMatch;
                }}
              />
            </FormGroup>
          </Col>

          <Col md={3}>
            <FormGroup>
              <Label for="name">{props.t("Product Name")}</Label>
              <DynamicInput
                type="text"
                name="name"
                id="name"
                value={formData.name}
                onChange={handleChange}
                placeholder={props.t("Enter product name")}
                required
              />
            </FormGroup>
          </Col>

          <Col md={3}>
            <FormGroup>
              <Label for="description">{props.t("Description")}</Label>
              <DynamicInput
                type="text"
                name="description"
                id="description"
                value={formData.description}
                onChange={handleChange}
                placeholder={props.t("Enter product description")}
              />
            </FormGroup>
          </Col>

          <Col md={3}>
            <FormGroup>
              <Label for="gtip_code">{props.t("GTIP Code")}</Label>
              <DynamicInput
                type="text"
                name="gtip_code"
                id="gtip_code"
                value={formData.gtip_code}
                onChange={handleChange}
                placeholder={props.t("Enter GTIP code")}
              />
            </FormGroup>
          </Col>

          <Col md={3}>
            <FormGroup>
              <Label for="document_attribute_id">{props.t("Document Reference ID")}</Label>
              <DynamicInput
                type="text"
                name="document_attribute_id"
                id="document_attribute_id"
                value={formData.document_attribute_id}
                onChange={handleChange}
                placeholder={props.t("Enter document ID")}
              />
            </FormGroup>
          </Col>

          <Col md={3}>
            <FormGroup>
              <Label for="additional_attribute">{props.t("Additional Attribute")}</Label>
              <DynamicInput
                type="text"
                name="additional_attribute"
                id="additional_attribute"
                value={formData.additional_attribute}
                onChange={handleChange}
                placeholder={props.t("Enter additional attribute")}
              />
            </FormGroup>
          </Col>
        </Row>
      </Form>

      <AcceptModal />
    </div>
  );
};

ProductForm.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation()(ProductForm)