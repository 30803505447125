import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";
import Breadcrumb from "./Breadcrumb/reducer";  

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";

// Chat
import chat from "./chat/reducer";

// Calendar
import calendar from "./calendar/reducer";

// Tasks
import tasks from "./tasks/reducer";

// Inslug ve InslugItem Reducers
import inslugReducer from "./depot/inslug/reducer";
import inslugItemReducer from "./depot/inslugItem/reducer";
import selectedProductsReducer from "./depot/selectedProducts/reducer";
import companyReducer from "./company/reducer";
import modalReducer from "./modal/reducer";
import externCompanyReducer from "./externCompany/reducer";
import updateInslugReducer from "./depot/updateInslug/reducer";
import productReducer from "./product/reducer";
import updateInslugItemReducer from "./depot/updateInslugItem/reducer";
import selectedUpdateProductsReducer from "./depot/selectedUpdateProducts/reducer";
import productGroupReducer from "./productGroup/reducer";
import clientProductReducer from "./clientProduct/reducer";
import countriesReducer from "./Definitions/country/reducer";
import citiesReducer from "./Definitions/city/reducer";
import timerReducer from "./timer/reducer";
import selectedClientProductsReducer from "./depot/selectedClientProducts/reducer";
import selectedUpdateClientProductsReducer from "./depot/selectedUpdateClientProducts/reducer";

const rootReducer = combineReducers({
  // Public
  Layout,
  // Breadcrumb items
  Breadcrumb,
  tasks,
  Login,
  Account,
  ForgetPassword,
  Profile,
  calendar,
  chat,

  // Inslug ve InslugItem
  inslug: inslugReducer,
  updateInslug: updateInslugReducer,
  inslugItems: inslugItemReducer,
  updateInslugItems: updateInslugItemReducer,
  selectedProducts: selectedProductsReducer,
  selectedUpdateProducts: selectedUpdateProductsReducer,

  // Company
  companies: companyReducer,
  externCompanies: externCompanyReducer,

  // Product
  products: productReducer,
  productGroups: productGroupReducer,
  clientProducts: clientProductReducer,
  selectedClientProducts: selectedClientProductsReducer,
  selectedUpdateClientProducts: selectedUpdateClientProductsReducer,

  //Definitions
  countries: countriesReducer,
  cities: citiesReducer,
  
  // Modal
  modal: modalReducer,
  timerOpen: timerReducer

});

export default rootReducer;
