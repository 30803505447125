import React, { useState, useEffect } from 'react';
import { Button, Form, FormGroup, Label, Row, Col } from 'reactstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { setCities } from '../../../store/Definitions/city/action';
import { getCity, createCity } from '../../../service/Definitions/cityService';
import DynamicInput from '../../../components/Common/Form/DynamicInput';
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import { createCountry, getCountries } from 'service/Definitions/countryService';
import DynamicCreatableSelect from 'components/Common/Form/DynamicCreatableSelect';
import { closeModal, openModal } from 'store/modal/actions';

const CityForm = props => {
  const dispatch = useDispatch();

  // Form state, JSON yapısına göre düzenlendi
  const [formData, setFormData] = useState({});
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [countries, setCountries] = useState([]);
  useEffect(() => {

    const fetchData = async () => {
      try {
        const countryList = await getCountries();
        console.log(countries)
        setCountries(countryList)
      } catch (error) {
        toast.error(props.t("toast-error-fetch-data"), { position: "top-right" });
      }
    };
    fetchData();
  }, [dispatch]);


  useEffect(() => {
    console.log("Formdata güncellendi");
    console.log(formData);
  }, [formData])


  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value.toLocaleUpperCase("tr"),
    });
  };

  const handleSelectChange = (field, selected) => {
    console.log(selected);
    setSelectedCountry(selected)
    if (selected) {
      setFormData({ ...formData, [field]: selected.value });
    }
  };

/*   const handleCreateCountry = async (name) => {
    dispatch(
      openModal({
        title: props.t("Add Product Group"),
        body: <span>{props.t("Are you sure you want to add a new product group?")}</span>,
        confirmLabel: props.t("Add"),
        cancelLabel: props.t("Cancel"),
        onConfirm: async () => {
          const data = { name: name };
          await createCountry(data)
            .then(async (id) => {
              toast.success(props.t("Product group added successfully!"), { position: "top-right" });
              const countryList = await getCountries(); // Ürünleri güncelle
              setCountries(countryList);
              const newData = {value: id, label: name};
              handleSelectChange("product_category_no", newData);
            })
            .catch((error) => {
              console.error("Ürün grubu eklenemedi:", error);
              toast.error(props.t("Product group addition failed."), { position: "top-right" });
            });
          dispatch(closeModal());
        }
      }));// Modalı açıyoruz
  }; */

  const handleSubmit = (e) => {
    e.preventDefault();
    handleConfirm();
  };

  const handleConfirm = async () => {
    dispatch(
      openModal({
        title: props.t("modal-save-title"),
        body: props.t("modal-save-body"),
        onConfirm: async () => {
          try {
            await createCity(formData)
            .then(async () => {
              toast.success(props.t("toast-success-save-data"), { position: "top-right" });
              const countries = await getCity(); // Ürünleri güncelle
              dispatch(setCities(countries)); // Redux state güncelleme
              setSelectedCountry(null);
              setFormData({});
            })
            dispatch(closeModal()); // Close the modal after successful update

          } catch (error) {
            console.error("Ürün grubu eklenemedi:", error);
            toast.error(props.t("toast-error-save-data"), { position: "top-right" });
            dispatch(closeModal()); // Close the modal after successful update
          }
        }
      })
    );  
  };

  const handleCreateCountry = (inputValue) => {
    dispatch(
        openModal({
            title: props.t("modal-save-title"),
            body: <span>{props.t("modal-save-body")}</span>,
            onConfirm: async () => {
                // Yeni bir şirket oluşturmak için:
                const newCountry = {
                    name: inputValue,  // Kullanıcının girdiği değer
                };
  
                await createCountry(newCountry)
                    .then(async (id) => {
                        const newCountryList = await getCountries();// Yeni şirketleri yeniden getir
                        const newSelected = newCountryList.find(data => data.id === id);
                        const formattedCountries = newCountryList.map(country => ({
                            value: country.id,
                            label: country.name
                          }));
                        setCountries(formattedCountries);
                        setSelectedCountry({ value: newSelected.id, label: newSelected.name });
                        setFormData({ ...formData, ["id"]: newSelected.id });
                        toast.success(props.t("toast-success-save-data"), { position: "top-right" });
                    })
                    .catch((error) => {
                        toast.error(props.t("toast-error-save-data"), { position: "top-right" });
                    }); dispatch(closeModal());
            }
        }));// Modalı açıyoruz
  }

  return (
    <div>
      <Form onSubmit={handleSubmit}>
{/*         <div className='d-flex justify-content-between mb-4'>
          <h4 className="text-info mb-4">{props.t("Add Country")}</h4>
          <button className='btn btn-md bg-success text-white' color="primary" type="submit">
            {props.t("Save")}
          </button>
        </div> */}
        <div className='d-flex justify-content-between mb-4'>
          <h4 className="text-primary">{props.t("Add City")}</h4>
          <button className='btn btn-md bg-success text-white' color="primary" type="submit">
            {props.t("Save")}
          </button>
        </div>
        <Row className='mb-4' form>
          <Col md={4}>
            <FormGroup>
              <Label for="product_category_no">{props.t("Country")}</Label>
              <DynamicCreatableSelect
                options={countries.map((country) => ({
                  value: country.id,  // value'da client_no kullanıyoruz
                  label: country.name,  // label'da company.name kullanıyoruz
                }))}
                value={selectedCountry ? selectedCountry : null}
                onChange={(selected) => handleSelectChange("country_id", selected)} // Kategori seçimi
                onCreateOption={async (inputValue) => { handleCreateCountry(inputValue) }}
                placeholder={props.t("Select country")}
                noOptionsMessage={() => props.t("no-option-message")}
                onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                isClearable
                required
                formatCreateLabel={(inputValue) => `${props.t("Add")}: ${inputValue}`} // Yeni seçenek ekleme mesajı
                filterOption={(option, inputValue) => {
                  // Hem client_no hem de company.name üzerinden arama yapabilmek için
                  const companyNameMatch = option.label.toLowerCase().includes(inputValue.toLowerCase());

                  return companyNameMatch;
                }}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="name">{props.t("City Name")}</Label>
              <DynamicInput
                type="text"
                name="name"
                id="name"
                value={formData.name}
                onChange={handleChange}
                placeholder={props.t("Enter city name")}
                required
              />
            </FormGroup>
          </Col>
        </Row>
      </Form>

    </div>
  );
};

CityForm.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation()(CityForm)