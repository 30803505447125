import React, { useState, useEffect } from 'react';
import { Button, Form, FormGroup, Label, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { setCountries } from '../../../store/Definitions/country/action';
import { getCountries, createCountry } from '../../../service/Definitions/countryService';
import DynamicInput from '../../../components/Common/Form/DynamicInput';
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import AcceptModal from 'components/Common/Modals/acceptModal';
import { closeModal, openModal } from 'store/modal/actions';

const CountriesForm = props => {
  const dispatch = useDispatch();

  // Form state, JSON yapısına göre düzenlendi
  const [formData, setFormData] = useState({});

  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value.toLocaleUpperCase("tr"),
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleConfirm(); // Modalı açıyoruz
  };

  useEffect(() => {
    console.log("Formdata güncellendi");
    console.log(formData);
  }, [formData])

  const handleConfirm = async () => {
    dispatch(
      openModal({
        title: props.t("modal-save-title"),
        body: props.t("modal-save-body"),
        onConfirm: async () => {
          try {
            await createCountry(formData)
              .then(async () => {
                toast.success(props.t("toast-success-save-data"), { position: "top-right" });
                const countries = await getCountries(); // Ürünleri güncelle
                dispatch(setCountries(countries)); // Redux state güncelleme
                setFormData({});
              })
            dispatch(closeModal()); // Close the modal after successful update

          } catch (error) {
            console.error("Ürün grubu eklenemedi:", error);
            toast.error(props.t("toast-error-save-data"), { position: "top-right" });
            dispatch(closeModal()); // Close the modal after successful update
          }
        }
      })
    );

  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <div className='d-flex justify-content-between mb-4'>
          <h4 className="text-primary">{props.t("Add Country")}</h4>
          <button className='btn btn-md bg-success text-white' color="primary" type="submit">
            {props.t("Save")}
          </button>
        </div>
        <Row className='mb-4' form>
          <Col md={4}>
            <FormGroup>
              <Label for="name">{props.t("Country Name")}</Label>
              <DynamicInput
                type="text"
                name="name"
                id="name"
                value={formData.name}
                onChange={handleChange}
                placeholder={props.t("Enter country name")}
                required
              />
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

CountriesForm.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation()(CountriesForm)