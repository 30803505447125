import React, { useState, useEffect } from 'react';
import { Button, ButtonGroup, Input } from 'reactstrap';
import { FaEdit, FaTrash, FaCheck, FaTimes } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { setProducts } from '../../store/product/action'; // Redux actions
import { deleteProduct, getProducts,  updateProduct,  } from 'service/productService'; // API functions
import { openModal, closeModal } from '../../store/modal/actions'; // Modal actions
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AcceptModal from 'components/Common/Modals/acceptModal';
import jsPDF from 'jspdf';
import 'jspdf-autotable'; // PDF export için
import * as XLSX from 'xlsx'; // Excel export için
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import excel from "../../assets/icons/excel-white.png";
import customStyles from 'styles/customDatatableStyle';

const ProductList = props => {
  const dispatch = useDispatch();

  const [editingRow, setEditingRow] = useState(null); // Row being edited
  const [formData, setFormData] = useState({}); // State for form data
  const [searchText, setSearchText] = useState(''); // State for search text

  const products = useSelector((state) => state.products?.products || []);

  const [filteredProducts, setFilteredCompanies] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const productList = await getProducts(); // Fetch companies from API
        dispatch(setProducts(productList));  // Dispatch to Redux store
        setFilteredCompanies(productList);
      } catch (error) {
        console.error('Error fetching companies:', error);
        toast.error(props.t("There was a problem fetching the products."), { position: "top-right" });
      }
    };
    fetchData();
  }, [dispatch]);


  // Search text'e göre filtreleme işlemi
  useEffect(() => {
    console.log("filteredelecek company");
    console.log(products);
    const filteredData = products.filter((product) =>
      product.product_no?.toLowerCase().includes(searchText.toLowerCase()) ||
      product.name?.toLowerCase().includes(searchText.toLowerCase()) ||
      product.product_category_no?.toLowerCase().includes(searchText.toLowerCase()) ||
      product.description?.toLowerCase().includes(searchText.toLowerCase())

    );
    setFilteredCompanies(filteredData);
  }, [searchText, products]);

  const handleEdit = (id, rowIndex) => {
    const selectedData = filteredProducts.find(data => data.id === id);
    setEditingRow(rowIndex); // Set the row for editing
    setFormData(selectedData); // Set the form data for the current row
  };

  const handleSave = (rowIndex) => {
    dispatch(
      openModal({
        title: props.t("Update Product"),
        body: props.t("Are you sure you want to update the product information?"),
        confirmLabel: props.t("Update"),
        cancelLabel: props.t("Cancel"),
        onConfirm: async () => {
          try {
              await updateProduct(formData.id, formData); // Call API to update company
              toast.success(props.t("Product updated successfully!"), { position: "top-right" });

              const updatedProducts = await getProducts(); // Fetch updated companies
              dispatch(setProducts(updatedProducts)); // Update Redux state
              setFilteredCompanies(updatedProducts); // Update filtered companies
              setEditingRow(null); // Exit edit mode
              dispatch(closeModal()); // Close the modal after successful update

          } catch (error) {
            console.error('Error updating company:', error);
            toast.error(props.t("Product information could not be updated."), { position: "top-right" });
            dispatch(closeModal()); // Close the modal after failure
          }
        }
      })
    );
  };

  const handleDelete = (id) => {
    dispatch(
      openModal({
        title: props.t("Delete Product"),
        body: props.t("Are you sure you want to delete the selected product?"),
        confirmLabel: props.t("Delete"),
        cancelLabel: props.t("Cancel"),
        onConfirm: async () => {
          try {
              await deleteProduct(id); // Delete company
              toast.success(props.t("Product deleted successfully!"), { position: "top-right" });

              const updatedProducts = await getProducts(); // Fetch updated companies
              dispatch(setProducts(updatedProducts)); // Update Redux state
              setFilteredCompanies(updatedProducts); // Update filtered companies
              dispatch(closeModal()); // Close the modal after successful update
          } catch (error) {
            console.error('Şirket silinirken bir hata oluştu:', error);
            toast.error(props.t("There was a problem deleting the product."), { position: "top-right" });
            dispatch(closeModal()); // Close the modal after failure
          }
        }
      })
    );
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const exportToExcel = () => {
    const column1Name = props.t("Product Code");
    const column2Name = props.t("Product Name");
    const column3Name = props.t("Product Group Code");
    const column4Name = props.t("Description");

    // Sadece 1. ve 2. sütunları (veri) seçiyoruz
    const selectedColumns = filteredProducts.map(itemProducts => ({
      [column1Name]: itemProducts.product_no, // 1. sütun verisi
      [column2Name]: itemProducts.name, // 2. sütun verisi
      [column3Name]: itemProducts.product_category_no, // 2. sütun verisi
      [column4Name]: itemProducts.description, // 2. sütun verisi
    }));

    const worksheet = XLSX.utils.json_to_sheet(selectedColumns);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, props.t("Products"));
    XLSX.writeFile(workbook, props.t("Products")+".xls");
  };

  const columns = [
    {
      name: props.t("Product Code"),
      selector: (row, rowIndex) =>
        row.product_no,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Product Name"),
      selector: (row, rowIndex) => editingRow === rowIndex ? (
        <Input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
        />
      ) : (
        row.name
      ),
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Product Group Code"),
      selector: (row, rowIndex) => editingRow === rowIndex ? (
        <Input
          type="text"
          name="product_category_no"
          value={formData.product_category_no}
          onChange={handleInputChange}
        />
      ) : (
        row.product_category_no
      ),
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Description"),
      selector: (row, rowIndex) => editingRow === rowIndex ? (
        <Input
          type="text"
          name="description"
          value={formData.description}
          onChange={handleInputChange}
        />
      ) : (
        row.description
      ),
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Actions"),
      cell: (row, rowIndex) =>
        editingRow === rowIndex ? (
          <>
            <Button className='m-1 btn btn-sm' color="success" onClick={() => handleSave(rowIndex)}>
              <FaCheck /> {/* Save */}
            </Button>
            <Button className='m-1 btn btn-sm' color="danger" onClick={() => setEditingRow(null)}>
              <FaTimes /> {/* Cancel */}
            </Button>
          </>
        ) : (
          <>
            <Button color="link" onClick={() => handleEdit(row.id, rowIndex)}>
              <FaEdit color="green" size={18} />
            </Button>
            <Button color="link" onClick={() => handleDelete(row.id)}>
              <FaTrash color="red" size={18} />
            </Button>
          </>
        ),
    },
  ];
  const customPaginationOptions = {
    rowsPerPageText: 'Sayfa başına satır:', // Burada yazıyı özelleştiriyoruz
    rangeSeparatorText: 'ile',
  };

  return (
    <div className="my-4">
      <h4 className="text-primary mb-4">{props.t("Product List")}</h4>

      <DataTable
        columns={columns}
        data={filteredProducts}
        customStyles={customStyles}
        noDataComponent={props.t("table-empty-message")} // Boş veri mesajını özelleştiriyoruz
        paginationComponentOptions={customPaginationOptions} // Özelleştirilmiş yazı ekledik
        pagination
        highlightOnHover
        subHeader
        className='p-3'
        subHeaderComponent={
            <div className='d-flex justify-content-end w-50'>
              <Button className="me-2 p-0 " color="success p-2" onClick={exportToExcel}>
                <img src={excel} width={'24'} alt="" />
              </Button>
              <Input
                type="text"
                placeholder={props.t("Search")}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value.toLocaleUpperCase("tr"))} // Search input update
                style={{ width: '250px' }} // Smaller input field
              />
            </div>
        }
      />

      {/* Modal rendered by Redux */}
      <AcceptModal />
    </div>
  );
};

export default withTranslation()(ProductList)