// inslugItem/actions.js

import { ADD_UPDATE_INSLUG_ITEM_ROW, SET_UPDATE_INSLUG_ITEM_DATA, RESET_UPDATE_INSLUG_ITEMS, REMOVE_UPDATE_LAST_INSLUG_ITEM_ROW, SET_UPDATE_INSLUG_ITEMS_ALL } from './actionTypes';

// Action to add a new row of inslug items
export const addUpdateInslugItemRow = () => {
  return {
    type: ADD_UPDATE_INSLUG_ITEM_ROW,
    payload: {
      client_no: null,
      container_no: null,
      description: null,
      leverage_charge: null,
    },
  };
};

// Action to set data in the inslug items form
export const setUpdateInslugItemData = (index, field, value) => {
  return {
    type: SET_UPDATE_INSLUG_ITEM_DATA,
    payload: { index, field, value },
  };
};

// Son satırı silme aksiyonu
export const removeUpdateLastInslugItemRow = () => {
  return {
    type: REMOVE_UPDATE_LAST_INSLUG_ITEM_ROW
  };
};

// Action to reset the inslug items
export const resetUpdateInslugItems = () => {
  return {
    type: RESET_UPDATE_INSLUG_ITEMS,
  };
};

// Action to reset the inslug items
export const setUpdateInslugItemsAll = (data) => {
  return {
    type: SET_UPDATE_INSLUG_ITEMS_ALL,
    payload: data
  };
};


