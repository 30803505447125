import React, { useEffect, useState } from 'react';
import { Row, Col, Form, FormGroup, Label, Input, Alert, Card, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getInslug, inslugInfo } from '../../../../service/inslugService';
import DynamicSelect from 'components/Common/Form/DynamicSelect';
import DynamicInput from 'components/Common/Form/DynamicInput';
import DynamicCreatableSelect from 'components/Common/Form/DynamicCreatableSelect';
import { createCompany, createExternCompany } from 'service/companyService';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from 'react-router-dom';
import { setUpdateInslugData } from 'store/depot/updateInslug/actions';
import AcceptModal from 'components/Common/Modals/acceptModal';
import { openModal, closeModal } from '../../../../store/modal/actions'; // Modal actions
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import UpdateInslugActions from './updateInslugActions';
import { setSelectedUpdateClientProducts } from 'store/depot/selectedUpdateClientProducts/actions';

const UpdateInslug = props => {
  const dispatch = useDispatch();

  // Redux state
  const newInslug = useSelector((state) => state.updateInslug);
  const selectedProducts = useSelector((state) => state.selectedUpdateProducts?.selectedUpdateProducts || []);
  /*   console.log("updateeeeeeeeeee");
    console.log(newInslug); */
  const { id } = useParams();

  // Local state
  const [companies, setCompanies] = React.useState([]);
  const [externCompanies, setExternCompanies] = React.useState([]);
  const [customsStatuses, setCustomsStatuses] = React.useState([]);
  const [carrierTypes, setCarrierTypes] = React.useState([]);
  const [loadingStatuses, setLoadingStatuses] = React.useState([]);
  const [processStatuses, setProcessStatuses] = React.useState([]);
  const [selectedClient, setSelectedClient] = useState(null); // selected_client için ayrı state
  /*   const [defaultDate, setDefaultDate] = useState("");
   */
  const [modalOpen, setModalOpen] = useState(false);
  const [pendingInputValue, setPendingInputValue] = useState(null); // Modal onayı beklenen input değeri

  useEffect(() => {
    const dateFormatter = (date) => {
      return date.split('T')[0];
    }
    // Fetch data asynchronously



    const setDatas = async (comps, inslug) => {
      /*       console.log("cooommppssssssssssss");
            console.log(inslug);
            console.log(comps); */
      const client = comps.find(company => company.client_no === inslug.client_no);
      const value = customOption({ client_no: client ? client.client_no : "Silindi", company_name: client ? client.name : "Silindi" });

      dispatch(setUpdateInslugData({ ["id"]: inslug.id }));
      dispatch(setUpdateInslugData({ "selected_client": value }));
      dispatch(setUpdateInslugData({ client_no: client ? client.client_no : "Silindi" }));
      dispatch(setUpdateInslugData({ ["transport_company_no"]: inslug.transport_company_no }));
      dispatch(setUpdateInslugData({ ["document_no"]: inslug.document_no }));
      dispatch(setUpdateInslugData({ ["extern_document_no"]: inslug.extern_document_no }));
      dispatch(setUpdateInslugData({ ["agency_reference"]: inslug.agency_reference }));
      dispatch(setUpdateInslugData({ ["description"]: inslug.description }));
      dispatch(setUpdateInslugData({ ["booking_date"]: inslug.booking_date ? dateFormatter(inslug.booking_date) : null }));
      dispatch(setUpdateInslugData({ ["eta"]: inslug.eta ? dateFormatter(inslug.eta) : null }));
      dispatch(setUpdateInslugData({ ["closing_out"]: inslug.closing_out ? dateFormatter(inslug.closing_out) : null }));
      dispatch(setUpdateInslugData({ ["closing_in"]: inslug.closing_in ? dateFormatter(inslug.closing_in) : null }));
      dispatch(setUpdateInslugData({ ["customs_status_id"]: inslug.customs_status_id }));
      dispatch(setUpdateInslugData({ ["supplier_company_no"]: inslug.supplier_company_no }));
      dispatch(setUpdateInslugData({ ["carrier_type_id"]: inslug.carrier_type_id }));
      dispatch(setUpdateInslugData({ ["loading_status_id"]: inslug.loading_status_id }));
      dispatch(setUpdateInslugData({ ["process_status_id"]: inslug.process_status_id }));
      dispatch(setUpdateInslugData({ ["count"]: inslug.count }));
    }

    const fetchData = async () => {
      /* console.log("000000000000");
      console.log(id); */
      await getInslug(id)
        .then(async (inslugData) => {
          await inslugInfo()
            .then((inslugInfos) => {
              // JSON array verilerini local state'e ayarlıyoruz
              setCompanies(inslugInfos.company);
              setExternCompanies(inslugInfos.externCompany);
              setCustomsStatuses(inslugInfos.customsStatus);
              setCarrierTypes(inslugInfos.carrierType);
              setLoadingStatuses(inslugInfos.loadingStatus);
              setProcessStatuses(inslugInfos.processStatus);
              setDatas(inslugInfos.company, inslugData.inslug);
            })
            .catch(error => {
              toast.error(props.t("An error occurred while fetching the data."), { position: "top-right" });
            });
        })
        .catch(error => {
          toast.error(props.t("An error occurred while fetching the data."), { position: "top-right" });
        });
    };
    fetchData();
  }, [dispatch]);

  const toggleConfirmationModal = () => {
    setModalOpen(!modalOpen); // Modal açma/kapatma işlemi
  };

  const customLabel = (product) => {
    return <div className="d-flex align-items-center">
      <span className='shadow rounded bg-info px-1 text-white mx-2' color="info" pill>{`${product.client_no}`}</span>
      <span className=''>{product.company_name}</span>
      <span className='mx-2'>|</span>
      <span className='rounded bg-warning px-1 text-white mx-2' color="info" pill>{`${product.product_no}`}</span>
      <span>{product.product_name}</span>
    </div>;
  }


  useEffect(() => {
    // Fetch data asynchronously
    /*     console.log("Redux çalıştı newInslug güncellendi");
        console.log(newInslug); */
  }, [newInslug]);

  /*   useEffect(() => {
      // Günün tarihini al ve 'YYYY-MM-DD' formatına getir
      const today = new Date();
      const formattedDate = today.toISOString().split('T')[0]; // 'YYYY-MM-DD' formatında bir string
      setDefaultDate(formattedDate);
      handleDate({ 'name': 'booking_date', 'value': formattedDate })
    }, []); */

  // Handle input changes and dispatch actions to update Redux state
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    dispatch(setUpdateInslugData({ [name]: value.toLocaleUpperCase("tr") }));
    /*  console.log(newInslug); */
  };

  // Handle input changes and dispatch actions to update Redux state
  const handleCountChange = (e) => {
    const value = e.target.value.replace(/[^\d]/g, "");
    const name = e.target.name;
    dispatch(setUpdateInslugData({ [name]: value }));
  };

  const handleCreateWithConfirmation = (inputValue) => {
    dispatch(
      openModal({
        title: props.t("modal-save-title"),
        body: props.t("modal-save-body"),
        onConfirm: async () => {
          handleCreateOption(inputValue);
          dispatch(closeModal());
        }
      }));// Modalı açıyoruz
  };

  const handleSelectChange = (field, selectedOption) => {
    const value = selectedOption ? selectedOption.value : '';
    console.log("value : " + value);
    dispatch(setUpdateInslugData({ [field]: value }));
/*     console.log(newInslug);
 */  };

  const handleSelectChangeClientNo = (selectedOption) => {
    dispatch(setSelectedUpdateClientProducts(null));
    selectedProducts.forEach((element, index) => {
      dispatch(setUpdateInslugData(index, "client_product_id", null));
      dispatch(setUpdateInslugData({ client_no: null }));
    });
    if (selectedOption) {
      const value = selectedOption.value;
      setSelectedClient(value);  // selected_client'ı local state'e ekliyoruz
      dispatch(setUpdateInslugData({ client_no: selectedOption.client_id }));
      dispatch(setUpdateInslugData({ "selected_client": value }));

    } else {
      // Eğer seçilen değer temizlendiyse (null ise), client_no alanını temizle
      setSelectedClient(null);
      dispatch(setUpdateInslugData({ client_no: null }));
      dispatch(setUpdateInslugData({ "selected_client": null }));

    }
  };


  const customOption = ({ client_no, company_name }) => (
    <div className="d-flex align-items-center">
      <div className="client-no bg-info text-white rounded px-2 py-1 mr-2">
        {client_no}
      </div>
      <div className="company-name px-2">{company_name}</div>
    </div>
  );

  const handleCreateExternCompany = (inputValue) => {
    dispatch(
      openModal({
        title: props.t("modal-save-title"),
        body: props.t("modal-save-body"),
        onConfirm: async () => {
          // Yeni bir şirket oluşturmak için:
          const newCompany = {
            client_no: Math.random().toString(36).substr(2, 8).toUpperCase(), // Rastgele 8 karakterli müşteri numarası
            name: inputValue,  // Kullanıcının girdiği değer
          };

          await createExternCompany(newCompany)
            .then(async () => {
              const inslugInfos = await inslugInfo(); // Yeni şirketleri yeniden getir

              // Yeni şirketi listeye ekle
              setExternCompanies(inslugInfos.externCompany);
              toast.success(props.t("toast-success-save-data"), { position: "top-right" });

              // Yeni şirketi otomatik olarak seç
              const selectedItem = { value: newCompany.client_no, label: newCompany.name };
              dispatch(setUpdateInslugData({ supplier_company_no: newCompany.client_no }));
            })
            .catch((error) => {
              toast.error(props.t("toast-error-save-data"), { position: "top-right" });
            }); dispatch(closeModal());
        }
      }));// Modalı açıyoruz
  }



  // Yeni bir müşteri veya şirket ekleme
  const handleCreateOption = async (inputValue) => {
    /*  console.log("handlecreateoption");
     console.log(inputValue); */
    const newCompany = {
      client_no: Math.random().toString(36).substr(2, 8).toUpperCase(), // Rastgele 8 karakterli müşteri numarası
      name: inputValue,  // Kullanıcının girdiği değer
    };

    await createCompany(newCompany)
      .then(async () => {
        const inslugInfos = await inslugInfo(); // API'den inslugInfo verisi alıyoruz

        // JSON array verilerini local state'e ayarlıyoruz
        setCompanies(inslugInfos.company);
        toast.success(props.t("toast-success-save-data"), { position: "top-right" });
        const value = customOption({ client_no: newCompany.client_no, company_name: newCompany.name });
        dispatch(setUpdateInslugData({ "selected_client": value }));
        dispatch(setUpdateInslugData({ client_no: newCompany.client_no }));
        //setSelectedClient(selected);
        //dispatch(setInslugData({ client_no: newCompany.client_no, selected_client: selectedItem.label }));
      })
      .catch(error => {
        toast.error(props.t("toast-error-save-data"), { position: "top-right" });
      });

  };

  return (
    <Card className='p-4 rounded-lg'>
      <div className='inslug'>
        <div className='d-flex justify-content-between mb-4'>
          <h4 className=''>{props.t("Inslug Information")}</h4>
          <UpdateInslugActions />
        </div>
        <Form >
          <Row form>
            <Col md={3}>
              <FormGroup>
                <Label for="client_no">{props.t("Company No / Company Name")}</Label>
                <DynamicCreatableSelect
                  name='client_no'
                  value={newInslug.selected_client ? { value: newInslug.selectedClient, label: newInslug.selected_client } : null}
                  options={companies.map((company) => ({
                    value: customOption({ client_no: company.client_no, company_name: company.name }),
                    label: customOption({ client_no: company.client_no, company_name: company.name }),
                    client_id: company.client_no,
                    company_name: company.name,
                  }))}
                  onChange={(selected) => handleSelectChangeClientNo(selected)}
                  onCreateOption={(inputValue) => { handleCreateWithConfirmation(inputValue) }}  // Yeni ürün ekleme
                  noOptionsMessage={() => props.t("no-option-message")}
                  placeholder={props.t("Company No / Company Name")}
                  formatCreateLabel={(inputValue) => `${props.t("Add")}: ${inputValue}`}
                  onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                  filterOption={(option, inputValue) => {
                    const clientNo = option.data.client_id || option.value;  // Eğer client_id undefined ise boş string yap
                    const companyName = option.data.company_name || option.label;  // Eğer company_name undefined ise boş string yap

                    const clientNoMatch = clientNo.toLowerCase().includes(inputValue.toLowerCase());
                    const companyNameMatch = companyName.toLowerCase().includes(inputValue.toLowerCase());

                    return clientNoMatch || companyNameMatch;
                  }}
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label for="transport_company_no">{props.t("Transport Company No")}</Label>
                <DynamicCreatableSelect
                  options={externCompanies.map((company) => ({
                    value: company.client_no,  // value'da client_no kullanıyoruz
                    label: company.name,  // label'da company.name kullanıyoruz
                  }))}
                  noOptionsMessage={() => props.t("no-option-message")}
                  value={
                    externCompanies.find(
                      (company) => company.client_no === newInslug.transport_company_no // Eşleşen durumu buluyoruz
                    )
                      ? {
                        value: externCompanies.find(
                          (company) => company.client_no === newInslug.transport_company_no
                        ).client_no, // value'da client_no kullanıyoruz
                        label: externCompanies.find(
                          (company) => company.client_no === newInslug.transport_company_no
                        ).name, // label'da company.name kullanıyoruz
                      }
                      : null // Eğer eşleşme yoksa null döner
                  }
                  onChange={(selected) => {
                    /* console.log(selected); */
                    handleSelectChange('transport_company_no', selected); // Seçilen client_no'yu işliyoruz
                  }}
                  onCreateOption={async (inputValue) => { handleCreateExternCompany(inputValue) }}
                  placeholder={props.t("Select transport company")}
                  isClearable
                  formatCreateLabel={(inputValue) => `Yeni ekle: ${inputValue}`} // Yeni seçenek ekleme mesajı
                  onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                  filterOption={(option, inputValue) => {
                    // Hem client_no hem de company.name üzerinden arama yapabilmek için
                    const clientNoMatch = option.value.toLowerCase().includes(inputValue.toLowerCase());
                    const companyNameMatch = option.label.toLowerCase().includes(inputValue.toLowerCase());

                    return clientNoMatch || companyNameMatch;
                  }}
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label for="booking_date">{props.t("Booking Date")}</Label>
                <DynamicInput
                  type="date"
                  id="booking_date"
                  name="booking_date"
                  value={newInslug.booking_date} // Eğer Redux'ta başka bir tarih yoksa defaultDate kullan
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label for="supplier_company_no">{props.t("Supplier Company")}</Label>
                <DynamicCreatableSelect
                  options={externCompanies.map((company) => ({
                    value: company.client_no,  // value'da client_no kullanıyoruz
                    label: company.name,  // label'da company.name kullanıyoruz
                  }))}
                  value={
                    externCompanies.find(
                      (company) => company.client_no === newInslug.supplier_company_no // Eşleşen durumu buluyoruz
                    )
                      ? {
                        value: externCompanies.find(
                          (company) => company.client_no === newInslug.supplier_company_no
                        ).client_no, // value'da client_no kullanıyoruz
                        label: externCompanies.find(
                          (company) => company.client_no === newInslug.supplier_company_no
                        ).name, // label'da company.name kullanıyoruz
                      }
                      : null // Eğer eşleşme yoksa null döner
                  }
                  onChange={(selected) => {
                    /* console.log(selected); */
                    handleSelectChange('supplier_company_no', selected); // Seçilen client_no'yu işliyoruz
                  }}
                  onCreateOption={async (inputValue) => { handleCreateExternCompany(inputValue) }}
                  placeholder={props.t("Select supplier company")}
                  isClearable
                  noOptionsMessage={() => props.t("no-option-message")}
                  formatCreateLabel={(inputValue) => `Yeni ekle: ${inputValue}`} // Yeni seçenek ekleme mesajı
                  onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                  filterOption={(option, inputValue) => {
                    // Hem client_no hem de company.name üzerinden arama yapabilmek için
                    const clientNoMatch = option.value.toLowerCase().includes(inputValue.toLowerCase());
                    const companyNameMatch = option.label.toLowerCase().includes(inputValue.toLowerCase());

                    return clientNoMatch || companyNameMatch;
                  }}
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label for="agency_reference">{props.t("Agency Reference")}</Label>
                <DynamicInput
                  type="text"
                  id="agency_reference"
                  name="agency_reference"
                  value={newInslug.agency_reference}
                  placeholder={props.t("Enter agency reference")}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label for="eta">{props.t("ETA")}</Label>
                <DynamicInput
                  type="date"
                  id="eta"
                  name="eta"
                  value={newInslug.eta}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label for="closing_out">{props.t("Closing Out")}</Label>
                <DynamicInput
                  type="date"
                  id="closing_out"
                  name="closing_out"
                  value={newInslug.closing_out}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label for="closing_in">{props.t("Closing In")}</Label>
                <DynamicInput
                  type="date"
                  id="closing_in"
                  name="closing_in"
                  value={newInslug.closing_in}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label for="document_no">{props.t("Document No")}</Label>
                <DynamicInput
                  type="text"
                  id="document_no"
                  name="document_no"
                  value={newInslug.document_no}
                  placeholder={props.t("Enter document no")}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label for="extern_document_no">{props.t("Ext Document No")}</Label>
                <DynamicInput
                  type="text"
                  id="extern_document_no"
                  name="extern_document_no"
                  value={newInslug.extern_document_no}
                  placeholder={props.t("Enter ext document no")}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label for="description">{props.t("Description")}</Label>
                <DynamicInput
                  type="text"
                  id="description"
                  name="description"
                  value={newInslug.description}
                  placeholder={props.t("Enter description")}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label for="customs_status_id">{props.t("Customs Status")}</Label>
                <DynamicSelect
                  options={customsStatuses.map((status) => ({
                    value: status.id,  // value, id değerini içerir
                    label: status.name, // label, name değerini içerir
                  }))}
                  value={
                    customsStatuses.find(
                      (status) => status.id === newInslug.customs_status_id // Eşleşen durumu bul
                    )
                      ? {
                        value: customsStatuses.find(
                          (status) => status.id === newInslug.customs_status_id
                        ).id, // value'da id değerini kullanıyoruz
                        label: customsStatuses.find(
                          (status) => status.id === newInslug.customs_status_id
                        ).name, // label'da name değerini kullanıyoruz
                      }
                      : null // Eğer eşleşme yoksa null döner, placeholder gösterilir
                  }
                  noOptionsMessage={() => props.t("no-option-message")}
                  onChange={(selected) => {
                    /*  console.log("onChange çalıştı");
                     console.log(selected); */
                    handleSelectChange("customs_status_id", selected); // Seçilen id'yi işliyoruz
                  }}
                  placeholder={props.t("Select customs status")}
                  isClearable
                />

              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label for="carrier_type_id">{props.t("Carrier Type")}</Label>
                <DynamicSelect
                  options={carrierTypes.map((carrier) => ({
                    value: carrier.id,
                    label: carrier.name,
                  }))}
                  value={
                    carrierTypes.find(
                      (status) => status.id === newInslug.carrier_type_id // Eşleşen durumu bul
                    )
                      ? {
                        value: carrierTypes.find(
                          (status) => status.id === newInslug.carrier_type_id
                        ).id, // value'da id değerini kullanıyoruz
                        label: carrierTypes.find(
                          (status) => status.id === newInslug.carrier_type_id
                        ).name, // label'da name değerini kullanıyoruz
                      }
                      : null // Eğer eşleşme yoksa null döner, placeholder gösterilir
                  }
                  noOptionsMessage={() => props.t("no-option-message")}
                  onChange={(selected) =>
                    handleSelectChange('carrier_type_id', selected)
                  }
                  placeholder={props.t("Select carrier type")}
                  isClearable
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label for="loading_status_id">{props.t("Loading Status")}</Label>
                <DynamicSelect
                  options={loadingStatuses.map((status) => ({
                    value: status.id,
                    label: status.name,
                  }))}
                  value={
                    loadingStatuses.find(
                      (status) => status.id === newInslug.loading_status_id // Eşleşen durumu bul
                    )
                      ? {
                        value: loadingStatuses.find(
                          (status) => status.id === newInslug.loading_status_id
                        ).id, // value'da id değerini kullanıyoruz
                        label: loadingStatuses.find(
                          (status) => status.id === newInslug.loading_status_id
                        ).name, // label'da name değerini kullanıyoruz
                      }
                      : null // Eğer eşleşme yoksa null döner, placeholder gösterilir
                  }
                  noOptionsMessage={() => props.t("no-option-message")}
                  onChange={(selected) =>
                    handleSelectChange('loading_status_id', selected)
                  }
                  placeholder={props.t("Select loading status")}
                  isClearable
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label for="process_status_id">{props.t("Process Status")}</Label>
                <DynamicSelect
                  options={processStatuses.map((status) => ({
                    value: status.id,
                    label: status.name,
                  }))}
                  value={
                    processStatuses.find(
                      (status) => status.id === newInslug.process_status_id // Eşleşen durumu bul
                    )
                      ? {
                        value: processStatuses.find(
                          (status) => status.id === newInslug.process_status_id
                        ).id, // value'da id değerini kullanıyoruz
                        label: processStatuses.find(
                          (status) => status.id === newInslug.process_status_id
                        ).name, // label'da name değerini kullanıyoruz
                      }
                      : null // Eğer eşleşme yoksa null döner, placeholder gösterilir
                  }
                  noOptionsMessage={() => props.t("no-option-message")}
                  onChange={(selected) =>
                    handleSelectChange('process_status_id', selected)
                  }
                  placeholder={props.t("Select process status")}
                  isClearable
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label for="count">{props.t("Count")}</Label>
                <DynamicInput
                  type="number"
                  id="count"
                  name="count"
                  value={newInslug.count}
                  placeholder={props.t("Enter container count")}
                  onChange={handleCountChange}
                />
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </div>
      {/* Onay Modalı */}
      <AcceptModal />
    </Card>
  );
};

UpdateInslug.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation()(UpdateInslug)