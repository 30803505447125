import React, { useState, useEffect } from 'react';
import CompanyForm from './companyForm'; // Şirket oluşturma ve düzenleme formu
import CompanyList from './companyList'; // Şirket listeleme bileşeni
import { Card } from 'reactstrap';
import { useTranslation } from 'react-i18next'; 
import { connect } from "react-redux";
import { setBreadcrumbItems } from "../../store/actions";

const Company = (props) => {
  const [refresh, setRefresh] = useState(false); // Şirket listesi yenileme durumu

  const handleRefresh = () => {
    setRefresh(!refresh); // Form başarıyla tamamlandığında listeyi yeniden yüklemek için state değiştirme
  };

  const { t } = useTranslation();
  document.title = t("Company");
  const breadcrumbItems = [];
  useEffect(() => {
    props.setBreadcrumbItems('', breadcrumbItems)
  },)

  return (
    <div>
      <Card className='shadow p-4 rounded-lg'>
        {/* Şirket oluşturma formu */}
        <CompanyForm onSuccess={handleRefresh} />
      </Card>
      {/* Şirket listesi, her form gönderiminden sonra güncellenir */}
      <Card className='shadow p-4 rounded-lg'>
        <CompanyList refresh={refresh} />
      </Card>
    </div>
  );
};

export default connect(null, { setBreadcrumbItems })(Company);