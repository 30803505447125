// inslugItem/reducer.js

import { ADD_INSLUG_ITEM_ROW, SET_INSLUG_ITEM_DATA, RESET_INSLUG_ITEMS, REMOVE_LAST_INSLUG_ITEM_ROW, SET_INSLUG_ITEMS_ALL } from './actionTypes';

const initialState = {
  inslugItems: [{

  }],
};

const inslugItemReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_INSLUG_ITEM_ROW:
      return {
        ...state,
        inslugItems: [...state.inslugItems, action.payload],
      };

    case SET_INSLUG_ITEM_DATA:
      const updatedInslugItems = state.inslugItems.map((item, index) => {
        if (index === action.payload.index) {
          return {
            ...item,
            [action.payload.field]: action.payload.value,
          };
        }
        return item;
      });
      return {
        ...state,
        inslugItems: updatedInslugItems,
      };

    case RESET_INSLUG_ITEMS:
      return {
        ...state,
        inslugItems: initialState.inslugItems,
      };

    case SET_INSLUG_ITEMS_ALL:
      return {
        ...state,
        inslugItems: action.payload,
      };

    case REMOVE_LAST_INSLUG_ITEM_ROW:
      if (state.inslugItems.length > 1) { // Eğer sadece 1 satır varsa, onu silme
        return {
          ...state,
          inslugItems: state.inslugItems.slice(0, -1), // Son elemanı kaldır
        };
      }
      return state; // Eğer tek bir satır varsa, bir işlem yapma

    default:
      return state;
  }
};

export default inslugItemReducer;
