/* const customStyles = {
    rows: {
        style: {
            borderBottom: '2px solid #EED3D9 !important',  // Satırların arasındaki çizgi rengi kırmızı olacak
        },
    },
    headRow: {
        style: {
            borderBottom: '2px solid #EED3D9 !important',  // Satırların arasındaki çizgi rengi kırmızı olacak
        },
    },
}; */
const customStyles = {
    rows: {
      style: {
        borderBottom: '2px solid #EED3D9 !important',  // Satırların arasındaki çizgi rengi
      },
    },
    headRow: {
      style: {
        borderBottom: '2px solid #EED3D9 !important',  // Başlık altındaki çizgi rengi
      },
    },
    tableWrapper: {
      style: {
        borderBottom: 'none',  // Tablo altındaki çizgiyi kaldırıyoruz
      },
    },
    pagination: {
      style: {
        borderTop: 'none',  // Eğer pagination'da da çizgi varsa onu kaldırıyoruz
      },
    },
  };
  
export default customStyles;
