import axios from 'axios';
// API URLs
import API_BASE_URL from '../config'; // config.js dosyasından base URL'i import ediyoruz
const API_MAIN_MENU = '/statistics';
const API_MAIN_MENU_GET = '/mainpage';



/**
 * Şirketleri listeleme fonksiyonu
 * @returns {Promise} - API cevabı
 */
export const getStatistics = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}${API_MAIN_MENU}${API_MAIN_MENU_GET}`);
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.error('Şirketler listelenirken bir hata oluştu:', error);
      throw error;
    }
};
