import React, { useState, useEffect } from 'react';
import { Button, ButtonGroup, Input } from 'reactstrap';
import { FaEdit, FaTrash, FaCheck, FaTimes } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { deleteClientProduct, getClientProducts, updateClientProduct, } from 'service/clientProductService'; // API functions
import { openModal, closeModal } from '../../store/modal/actions'; // Modal actions
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AcceptModal from 'components/Common/Modals/acceptModal';
import jsPDF from 'jspdf';
import 'jspdf-autotable'; // PDF export için
import * as XLSX from 'xlsx'; // Excel export için
import { setClientProducts } from 'store/clientProduct/action';
import '../../styles/custom/datatable.css'
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import excel from "../../assets/icons/excel-white.png";
import customStyles from 'styles/customDatatableStyle';
import customDatatableStyles from 'styles/customDatatableStyle';

const ClientProductList = props => {
  const dispatch = useDispatch();

  const [editingRow, setEditingRow] = useState(null); // Row being edited
  const [formData, setFormData] = useState({}); // State for form data
  const [searchText, setSearchText] = useState(''); // State for search text

  const clientProducts = useSelector((state) => state.clientProducts?.clientProducts || []);

  const [filteredClientProducts, setFilteredClientProducts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const clientProductList = await getClientProducts(); // Fetch companies from API
        console.log("112748121");
        console.log(clientProductList);
        dispatch(setClientProducts(clientProductList));  // Dispatch to Redux store
        setFilteredClientProducts(clientProductList);
      } catch (error) {
        console.error('Error fetching companies:', error);
        toast.error(props.t("toast-error-fetch-data"), { position: "top-right" });
      }
    };
    fetchData();
  }, [dispatch]);


  // Search text'e göre filtreleme işlemi
  useEffect(() => {
    console.log("filteredelecek company");
    console.log(clientProducts);
    const filteredData = clientProducts.filter((product) =>
      product.product_no?.toLowerCase().includes(searchText.toLowerCase()) ||
      product.product_name?.toLowerCase().includes(searchText.toLowerCase()) ||
      product.client_no?.toLowerCase().includes(searchText.toLowerCase()) ||
      product.company_name?.toLowerCase().includes(searchText.toLowerCase()) ||
      product.extern_no?.toLowerCase().includes(searchText.toLowerCase()) ||
      product.origin_country?.toLowerCase().includes(searchText.toLowerCase()) ||
      product.production_date?.toLowerCase().includes(searchText.toLowerCase())

    );
    setFilteredClientProducts(filteredData);
  }, [searchText, clientProducts]);

  const handleEdit = (rowIndex) => {
    setEditingRow(rowIndex); // Set the row for editing
    setFormData(filteredClientProducts[rowIndex]); // Set the form data for the current row
  };

  const handleSave = (rowIndex) => {
    dispatch(
      openModal({
        title: props.t("modal-update-title"),
        body: props.t("modal-update-body"),
        onConfirm: async () => {
          try {
            await updateClientProduct(formData.id, formData); // Call API to update company
            toast.success(props.t("toast-success-update-data"), { position: "top-right" });

            const updatedProducts = await getClientProducts(); // Fetch updated companies
            dispatch(setClientProducts(updatedProducts)); // Update Redux state
            setFilteredClientProducts(updatedProducts); // Update filtered companies
            setEditingRow(null); // Exit edit mode
            dispatch(closeModal()); // Close the modal after successful update

          } catch (error) {
            console.error('Error updating company:', error);
            toast.error(props.t("toast-error-update-data"), { position: "top-right" });
            dispatch(closeModal()); // Close the modal after failure
          }
        }
      })
    );
  };

  const handleDelete = (id) => {
    dispatch(
      openModal({
        title: props.t("modal-delete-title"),
        body: props.t("modal-delete-body"),
        onConfirm: async () => {
          try {
            await deleteClientProduct(id); // Delete company
            toast.success(props.t("toast-success-delete-data"), { position: "top-right" });

            const updatedProducts = await getClientProducts(); // Fetch updated companies
            dispatch(setClientProducts(updatedProducts)); // Update Redux state
            setFilteredClientProducts(updatedProducts); // Update filtered companies
            dispatch(closeModal()); // Close the modal after successful update
          } catch (error) {
            console.error('Şirket silinirken bir hata oluştu:', error);
            toast.error(props.t("toast-error-delete-data"), { position: "top-right" });
            dispatch(closeModal()); // Close the modal after failure
          }
        }
      })
    );
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const exportToExcel = () => {
    const column1Name = props.t("Company Code");
    const companyName = props.t("Company Name");
    const column2Name = props.t("Product Code");
    const productName = props.t("Product Name");
    const column3Name = props.t("Extern No");
    const column4Name = props.t("Origin Country");
    const column5Name = props.t("Production Date");

    // Sadece 1. ve 2. sütunları (veri) seçiyoruz
    const selectedColumns = filteredClientProducts.map(itemClientProduct => ({
      [column1Name]: itemClientProduct.client_no, // 1. sütun verisi
      [companyName]: itemClientProduct.company_name, // 1. sütun verisi
      [column2Name]: itemClientProduct.product_no, // 2. sütun verisi
      [productName]: itemClientProduct.product_name, // 1. sütun verisi
      [column3Name]: itemClientProduct.extern_no, // 2. sütun verisi
      [column4Name]: itemClientProduct.country_name, // 2. sütun verisi
      [column5Name]: new Date(itemClientProduct.production_date).toLocaleDateString(), // 2. sütun verisi
    }));

    const worksheet = XLSX.utils.json_to_sheet(selectedColumns);
    
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, props.t("Client Products"));
    XLSX.writeFile(workbook, props.t("Client Products")+".xlsx");
  };

  const columns = [
    {
      name: props.t("Company Code"),
      selector: (row, rowIndex) => row.client_no,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Company Name"),
      selector: (row, rowIndex) => row.company_name,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Product Code"),
      selector: (row, rowIndex) => row.product_no,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Product Name"),
      selector: (row, rowIndex) => row.product_name,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Extern No"),
      selector: (row, rowIndex) => editingRow === rowIndex ? (
        <Input
          type="text"
          name="extern_no"
          value={formData.extern_no}
          onChange={handleInputChange}
        />
      ) : (
        row.extern_no
      ),
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Origin Country"),
      selector: (row, rowIndex) => editingRow === rowIndex ? (
        <Input
          type="text"
          name="origin_country"
          value={formData.origin_country}
          onChange={handleInputChange}
        />
      ) : (
        row.origin_country
      ),
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Production Date"),
      selector: (row, rowIndex) => editingRow === rowIndex ? (
        <Input
          type="text"
          name="production_date"
          value={formData.production_date ? new Date(formData.production_date).toLocaleDateString() : ""}
          onChange={handleInputChange}
        />
      ) : (
        row.production_date ? new Date(row.production_date).toLocaleDateString() : ""
      ),
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Actions"),
      cell: (row, rowIndex) =>
        editingRow === rowIndex ? (
          <>
            <Button className='m-1 btn btn-sm' color="success" onClick={() => handleSave(rowIndex)}>
              <FaCheck /> {/* Save */}
            </Button>
            <Button className='m-1 btn btn-sm' color="danger" onClick={() => setEditingRow(null)}>
              <FaTimes /> {/* Cancel */}
            </Button>
          </>
        ) : (
          <>
            <Button color="link" onClick={() => handleEdit(rowIndex)}>
              <FaEdit color="green" size={18} />
            </Button>
            <Button color="link" onClick={() => handleDelete(row.id)}>
              <FaTrash color="red" size={18} />
            </Button>
          </>
        ),
    },
  ];

  const customPaginationOptions = {
    rowsPerPageText: props.t("Rows count"), // Burada yazıyı özelleştiriyoruz
    rangeSeparatorText: '/',
  };
  return (
    <div className="my-4">
      <h4 className="text-primary mb-4">{props.t("Company Product List")}</h4>

      <DataTable
        className='p-3'
        columns={columns}
        data={filteredClientProducts}
        customStyles={customStyles}
        pagination
        highlightOnHover
        noDataComponent={props.t("table-empty-message")} // Boş veri mesajını özelleştiriyoruz
        paginationComponentOptions={customPaginationOptions} // Özelleştirilmiş yazı ekledik
        subHeader
        subHeaderComponent={
          <div className='d-flex justify-content-end w-50'>
            <Button className="me-2 p-0 " color="success p-2" onClick={exportToExcel}>
              <img src={excel} width={'24'} alt="" />
            </Button>
            <Input
              type="text"
              placeholder={props.t("Search")}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value.toLocaleUpperCase("tr"))} // Search input update
              style={{ width: '250px' }} // Smaller input field
            />
          </div>
        }
      />

      {/* Modal rendered by Redux */}
      <AcceptModal />
    </div>
  );
};

ClientProductList.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation()(ClientProductList)