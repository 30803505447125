import React, { useState, useEffect } from 'react';
import CountriesForm from './countryForm'; // Şirket oluşturma ve düzenleme formu
import CountriesList from './countryList'; // Şirket listeleme bileşeni
import { Card } from 'reactstrap';

import { useTranslation } from 'react-i18next'; 
import { connect } from "react-redux";
import { setBreadcrumbItems } from "../../../store/actions";

const Country = (props) => {
  const [refresh, setRefresh] = useState(false); // Şirket listesi yenileme durumu

  const handleRefresh = () => {
    setRefresh(!refresh); // Form başarıyla tamamlandığında listeyi yeniden yüklemek için state değiştirme
  };
  const { t } = useTranslation();
  document.title = t("Country");
  const breadcrumbItems = [];
  useEffect(() => {
    props.setBreadcrumbItems('', breadcrumbItems)
  },)

  return (
    <div>
      <Card className='shadow p-4 rounded-lg'>
        <CountriesForm onSuccess={handleRefresh} />
      </Card>
      <Card className='shadow p-4 rounded-lg'>
        <CountriesList refresh={refresh} />
      </Card>
    </div>
  );
};

export default connect(null, { setBreadcrumbItems })(Country);