// /src/services/inslugService.js
import axios from 'axios';
import API_BASE_URL from '../config'; // config.js dosyasından base URL'i import ediyoruz
const API_DEPOT = '/depot'; // API base URL'i buraya yazın
const API_INSLUG_ITEM = '/inslug-item'; // API base URL'i buraya yazın
const API_INSLUG_ITEM_INFO = '/info'; // API base URL'i buraya yazın
const API_INSLUG_ITEM_GET = '/get'; // API base URL'i buraya yazın
const API_INSLUG_ITEM_CREATE = '/create'; // API base URL'i buraya yazın

/**
 * Inslug ekleme fonksiyonu
 * @param {Object} inslugData - Formda girilen inslug verileri
 * @returns {Promise} - API cevabı
 */
export const createInslugItem = async (inslugData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}${API_DEPOT}${API_INSLUG_ITEM}${API_INSLUG_ITEM_CREATE}`, inslugData);
    return response.data;
  } catch (error) {
    console.error('Inslug item eklenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Mevcut tüm Inslug'ları getirme fonksiyonu
 * @returns {Promise} - API cevabı
 */
export const getInslugItems = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}${API_DEPOT}${API_INSLUG_ITEM}${API_INSLUG_ITEM_GET}`);
    return response.data;
  } catch (error) {
    console.error('Inslug item getirilirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Inslug eklemek için gerekli olan verileri çeken fonskiyon
 * @returns {Promise} - API cevabı
 */
export const inslugItemInfo = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}${API_DEPOT}${API_INSLUG_ITEM}${API_INSLUG_ITEM_INFO}`);
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.error('Inslug item getirilirken bir hata oluştu:', error);
      throw error;
    }
  };

/**
 * Belirli bir inslug'ı ID'ye göre güncelleme fonksiyonu
 * @param {string} id - Güncellenecek Inslug'un ID'si
 * @param {Object} inslugData - Güncellenmiş Inslug verileri
 * @returns {Promise} - API cevabı
 */
export const updateInslugItem = async (id, inslugData) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/inslugs/${id}`, inslugData);
    return response.data;
  } catch (error) {
    console.error('Inslug item güncellenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Belirli bir inslug'ı ID'ye göre silme fonksiyonu
 * @param {string} id - Silinecek Inslug'un ID'si
 * @returns {Promise} - API cevabı
 */
export const deleteInslugItem = async (id) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/inslugs/${id}`);
    return response.data;
  } catch (error) {
    console.error('Inslug item silinirken bir hata oluştu:', error);
    throw error;
  }
};
