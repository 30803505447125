import axios from 'axios';
// API URLs
import API_BASE_URL from '../config'; // config.js dosyasından base URL'i import ediyoruz
const API_PRODUCT = '/product';
const API_PRODUCT_CREATE = '/create';
const API_PRODUCT_GET = '/get';
const API_PRODUCT_UPDATE = '/update';
const API_PRODUCT_DELETE = '/delete';
const API_PRODUCT_CATEGORY = '/product-category';

/**
 * Yeni şirket oluşturma fonksiyonu
 * @param {Object} companyData - Şirket oluşturma formunda girilen veriler
 * @returns {Promise} - API cevabı
 */
export const createProduct = async (productData) => {
  console.log("servis iç");
  console.log(productData);
  try {
    const response = await axios.post(`${API_BASE_URL}${API_PRODUCT}${API_PRODUCT_CREATE}`, productData);
    console.log("createCompany");
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Şirket eklenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirketleri listeleme fonksiyonu
 * @returns {Promise} - API cevabı
 */
export const getProducts = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}${API_PRODUCT}${API_PRODUCT_GET}`);
      console.log("burası getCompanies service");
      console.log(response.data);
      console.log("*******");
      return response.data;
    } catch (error) {
      console.error('Şirketler listelenirken bir hata oluştu:', error);
      throw error;
    }
};

/**
 * Şirketleri listeleme fonksiyonu
 * @returns {Promise} - API cevabı
 */
export const getProductCategory = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}${API_PRODUCT_CATEGORY}${API_PRODUCT_GET}`);
    console.log("burası getCompanies service");
    console.log(response.data);
    console.log("*******");
    return response.data;
  } catch (error) {
    console.error('Şirketler listelenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirket güncelleme fonksiyonu
 * @param {string} companyId - Güncellenmek istenen şirketin ID'si
 * @param {Object} companyData - Güncellenen şirket bilgileri
 * @returns {Promise} - API cevabı
 */
export const updateProduct = async (companyId, companyData) => {
  try {
    console.log ("comapnyData");
    console.log(companyData);
    const response = await axios.post(`${API_BASE_URL}${API_PRODUCT}${API_PRODUCT_UPDATE}`, companyData);
    return response.data;
  } catch (error) {
    console.error('Şirket güncellenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirket silme fonksiyonu
 * @param {string} companyId - Silinmek istenen şirketin ID'si
 * @returns {Promise} - API cevabı
 */
export const deleteProduct = async (id) => {
  try {
    const response = await axios.post(`${API_BASE_URL}${API_PRODUCT}${API_PRODUCT_DELETE}`, {id});
    return response.data;
  } catch (error) {
    console.error('Şirket silinirken bir hata oluştu:', error);
    throw error;
  }
};