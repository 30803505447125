import React, { useEffect } from 'react';
import { Button, Container, Row, Col, Form, FormGroup, Label, Input, Card } from 'reactstrap';

import { Link } from 'react-router-dom'; // React Router'dan Link kullanacağız
import MonthlyEarnings from 'pages/Dashboard/montly-earnings';
import MonthlyEarnings2 from 'pages/Dashboard/montly-earnings2';
import InslugList from './inslug/inslugList';
import { setBreadcrumbItems } from "../../store/actions";
import { connect } from "react-redux";
import DepotActions from './depotActions';
import { useTranslation } from 'react-i18next'; 

const Depot = (props) => {
  const { t } = useTranslation();
  document.title = t("Depot");
  const breadcrumbItems = [];
  
  useEffect(() => {
    props.setBreadcrumbItems('', breadcrumbItems)
  },)

  return (
    <React.Fragment>
      <div className="text-center">
        <div>
          <DepotActions />
        </div>
        <div>
          <Row>
            <Col lg={9}>
              <Card>
                <InslugList />
              </Card>
            </Col>
            <Col lg={3}>
              <MonthlyEarnings />
              <MonthlyEarnings2 />
            </Col>
          </Row>
        </div>
        {/* <div className="my-4">
          <Row className="justify-content-center mb-4 mx-0 px-0">
            <Col md={12} lg={6}>
              <MonthlyEarnings />
            </Col>
            <Col md={12} lg={6} >
              <MonthlyEarnings2 />
            </Col>
          </Row>
        </div> */}
      </div>
    </React.Fragment>
  );
};
export default connect(null, { setBreadcrumbItems })(Depot);