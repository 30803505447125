import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody, Label, Form, Alert, Input, FormFeedback } from 'reactstrap';
import logo from "../../assets/images/act-logo.webp";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import PropTypes from "prop-types";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import withRouter from 'components/Common/withRouter';

// actions
import { loginUser, socialLogin } from "../../store/actions";

//i18n
import { withTranslation } from "react-i18next"
import LanguageDropdown from 'components/CommonForBoth/TopbarDropdown/LanguageDropdown';

const Login = props => {
  document.title = props.t("login-title");

  const dispatch = useDispatch();

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {},
    validationSchema: Yup.object({
      email: Yup.string().required(props.t("Please enter your email address")),
      password: Yup.string().required(props.t("Please enter your password")),
    }),
    onSubmit: (values) => {
      dispatch(loginUser(values, props.router.navigate, props.t("Username or password is incorrect")));
    }
  });

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(prevState => !prevState); // Şifreyi göster/gizle
  };

  const selectLoginState = (state) => state.Login;
  const LoginProperties = createSelector(
    selectLoginState,
    (login) => ({
      error: login.error
    })
  );

  const {
    error
  } = useSelector(LoginProperties);

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">

                  <h3 className="text-center mt-5 mb-4">
                    <Link to="/" className="d-block auth-logo">
                      <img src={logo} alt="" className="auth-logo-dark" />
                    </Link>
                  </h3>

                  <div className="p-3">
                    <Form
                      className="form-horizontal mt-4"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                      }}
                    >
                      {error ? <Alert color="danger">{error}</Alert> : null}
                      <div className="mb-3">
                        <Label htmlFor="username">{props.t("Email Address")}</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder={props.t("Please enter your email address")}
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                          required
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label htmlFor="userpassword">{props.t("Password")}</Label>
                        <div className='d-flex jusify-content-between align-items-center'>
                          <Input
                            name="password"
                            value={validation.values.password || ""}
                            type={showPassword ? 'text' : 'password'}
                            placeholder={props.t("Please enter your password")}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.password && validation.errors.password ? true : false
                            }
                            required
                          />
                          <span
                            onClick={togglePasswordVisibility}
                            style={{
                              marginLeft:"10px",
                              cursor: 'pointer',
                              fontSize: '18px'

                            }}
                          >

                            <i className={`mdi ${showPassword ? 'mdi-eye' : 'mdi-eye-off'}`}></i>
                          </span>
                        </div>
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>
                      <Row className="text-center">
                        {/* <div className="col-6">
                          <div className="form-check">
                            <input type="checkbox" className="form-check-input" id="customControlInline" />
                            <label className="form-check-label" htmlFor="customControlInline">Remember me
                            </label>
                          </div>
                        </div> */}
                        <div className="text-center">
                          <button className="btn btn-primary w-md waves-effect waves-light" type="submit">{props.t("Login")}</button>
                        </div>
                      </Row>
                      <Row className="form-group mb-0 mt-3">
                        <Link to="/forgot-password" className="text-muted"><i className="mdi mdi-lock mb-0"></i> {props.t("Forgot your password?")}</Link>
                      </Row>
                    </Form>
                    <div className='text-center'>
                      <LanguageDropdown />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

    </React.Fragment>
  )
}

Login.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(Login))


/* Login.propTypes = {
  history: PropTypes.object,
}; */
