import axios from 'axios';
// API URLs
import API_BASE_URL from '../../config'; // config.js dosyasından base URL'i import ediyoruz
const DEFINITIONS = '/definitions';
const COUNTRY = '/country';
const CREATE = '/create';
const GET = '/get';
const UPDATE = '/update';
const DELETE = '/delete';

/**
 * Yeni şirket oluşturma fonksiyonu
 * @param {Object} data - Şirket oluşturma formunda girilen veriler
 * @returns {Promise} - API cevabı
 */
export const createCountry = async (data) => {
  console.log("servis iç");
  console.log(data);
  try {
    const response = await axios.post(`${API_BASE_URL}${DEFINITIONS}${COUNTRY}${CREATE}`, data);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Ülke eklenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirketleri listeleme fonksiyonu
 * @returns {Promise} - API cevabı
 */
export const getCountries = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}${DEFINITIONS}${COUNTRY}${GET}`);
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.error('Ülkeler listelenirken bir hata oluştu:', error);
      throw error;
    }
};

/**
 * Şirket güncelleme fonksiyonu
 * @param {Object} data - Güncellenen şirket bilgileri
 * @returns {Promise} - API cevabı
 */
export const updateCountry = async (data) => {
  try {
    console.log(data);
    const response = await axios.post(`${API_BASE_URL}${DEFINITIONS}${COUNTRY}${UPDATE}`, data);
    return response.data;
  } catch (error) {
    console.error('Ülke güncellenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirket silme fonksiyonu
 * @param {string} id - Silinmek istenen şirketin ID'si
 * @returns {Promise} - API cevabı
 */
export const deleteCountry = async (id) => {
  try {
    const response = await axios.post(`${API_BASE_URL}${DEFINITIONS}${COUNTRY}${DELETE}`, {id});
    return response.data;
  } catch (error) {
    console.error('Şirket silinirken bir hata oluştu:', error);
    throw error;
  }
};