import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import UpdateInslug from "./updateInslug";
import UpdateInslugItem from "./updateInslugItem";
import { setBreadcrumbItems } from "../../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { updateInslug } from "../../../../service/inslugService";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { resetUpdateInslugForm } from '../../../../store/depot/updateInslug/actions';
import { resetUpdateInslugItems, setUpdateInslugItemData } from '../../../../store/depot/updateInslugItem/actions';
import { setSelectedUpdateProducts } from "store/depot/selectedUpdateProducts/actions";
import UpdateInslugActions from "./updateInslugActions";
import { useTranslation } from 'react-i18next'; 

const UpdateInslugFormPage = (props) => {
    const { t } = useTranslation();
    document.title = t("Update Inslug");
    const breadcrumbItems = [];

    useEffect(() => {
        props.setBreadcrumbItems("", breadcrumbItems);
    }, []);


    return (
        <React.Fragment>
            <div>
                <UpdateInslug />
                <UpdateInslugItem />
                {/* <UpdateInslugActions /> */}
            </div>
        </React.Fragment>
    );
};

export default connect(null, { setBreadcrumbItems })(UpdateInslugFormPage);
