// store/auth/forgetpwd/saga.js
import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import axios from 'axios';

// Redux State Types
import { FORGET_PASSWORD, CHANGE_PASSWORD_REQUEST } from "./actionTypes";
import { 
  userForgetPasswordSuccess, 
  userForgetPasswordError, 
  changePasswordSuccess, 
  changePasswordError 
} from "./actions";
import { ErrorMessage } from "formik";

// Backend API URL
const API_BASE_URL = 'https://back.actnatie.com/api/users';

// Kullanıcı şifre sıfırlama isteği gönderme
const postForgetPassword = async (email) => {
  const response = await axios.post(`${API_BASE_URL}/reset-password`, { email });
  return response.data;
};

function* forgetUser({ payload: { user, history, successMessage, errorMessage } }) {
  try {
    const response = yield call(postForgetPassword, user.email);
    if (response) {
      yield put(userForgetPasswordSuccess(successMessage));
      
    }
  } catch (error) {
    yield put(userForgetPasswordError(errorMessage));
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser);
}

// Kullanıcı şifre değiştirme isteği gönderme
const postChangePassword = async ({ newPassword, token }) => {
  const response = await axios.post(`${API_BASE_URL}/change-password`, { newPassword, token });
  return response.data;
};

function* changePasswordSaga({ payload: { newPassword, token, navigate } }) {
  try {
    const response = yield call(postChangePassword, { newPassword, token });
    if (response.success) {
      yield put(changePasswordSuccess(response.message)); // Doğru action çağrısı
      navigate('/login'); // Şifre başarıyla değiştirildiyse login sayfasına yönlendir
    } else {
      yield put(changePasswordError(response.message));
    }
  } catch (error) {
    yield put(changePasswordError(error.response?.data?.message || 'Şifre değiştirme işlemi sırasında bir hata oluştu.'));
  }
}

export function* watchChangePassword() {
  yield takeEvery(CHANGE_PASSWORD_REQUEST, changePasswordSaga);
}

// Saga Root
function* rootSaga() {
  yield all([
    fork(watchUserPasswordForget),
    fork(watchChangePassword)
  ]);
}

export default rootSaga;
