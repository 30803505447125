import React, { useEffect, useState } from 'react';
import { Button, Table, FormGroup, Card, Badge } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useDispatch, useSelector } from 'react-redux';
import { addUpdateInslugItemRow, setUpdateInslugItemData, removeUpdateLastInslugItemRow, setUpdateInslugItemsAll } from '../../../../store/depot/updateInslugItem/actions';
import DynamicCreatableSelect from 'components/Common/Form/DynamicCreatableSelect';
import { inslugItemInfo } from 'service/inslugItemService';
import { useParams } from 'react-router-dom';
import { getInslug } from 'service/inslugService';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DynamicInput from 'components/Common/Form/DynamicInput';
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
/* import { setSelectedUpdateProducts } from 'store/depot/selectedUpdateProducts/actions';
 */
const UpdateInslugItem = props => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const updateInslugItemsData = useSelector((state) => state.updateInslugItems?.updateInslugItems || []);
  const selectedProducts = useSelector((state) => state.selectedUpdateProducts?.selectedUpdateProducts || []);
  const [clientProducts, setClientProducts] = useState([]);

  useEffect(() => {
    const updateSelectedProducts = (inslugItems, clientProducts) => {
      inslugItems.forEach((inslugItem, index) => {
        const product = clientProducts.find(product => product.value === inslugItem.client_product_id);
        console.log("produuuttccc");
        console.log(product);
        dispatch(setUpdateInslugItemData(index, "id", inslugItem.id));

        if (product) {
          console.log("eşleştii");
          console.log(product);
          dispatch(setUpdateInslugItemData(index, "client_no", product ? product.value : null));
          dispatch(setUpdateInslugItemData(index, "client_product_id", product ? product.value : null));

          /*        dispatch(setUpdateInslugItemData(index, "client_no", selectedOption ? selectedOption.value : null));
                 dispatch(setUpdateInslugItemData(index, "client_product_id", selectedOption ? selectedOption.value : null)); */

        }
      });
    };

    const setDatas = (inslugItem, clientProductData) => {
      dispatch(setUpdateInslugItemsAll(inslugItem));
      updateSelectedProducts(inslugItem, clientProductData);
    };

    const customLabel = (product) => {
      return <div className="d-flex align-items-center">
        <span className='shadow rounded bg-info px-1 text-white mx-2' color="info" pill>{`${product.client_no}`}</span>
        <span className=''>{product.company_name}</span>
        <span className='mx-2'>|</span>
        <span className='rounded bg-warning px-1 text-white mx-2' color="info" pill>{`${product.product_no}`}</span>
        <span>{product.product_name}</span>
      </div>;
    }

    const fetchData = async () => {
      try {
        const inslugData = await getInslug(id);
        const clientProductData = await inslugItemInfo();
        const formattedClientProducts = clientProductData.map(product => ({
          value: product.client_product_id,
          clientNo: product.client_no,
          companyName: product.company_name,
          productNo: product.product_no,
          productCategoryName: product.product_name,
          originCountry: product.origin_country,
          label: customLabel(product)
        }));
        setClientProducts(formattedClientProducts);
        setDatas(inslugData.inslugItems, formattedClientProducts);
      } catch (error) {
        toast.error(props.t("toast-error-fetch-data"), { position: "top-right" });
      }
    };

    fetchData();
  }, [dispatch, id]);



  const handleSelectChange = (index, field, selectedOption) => {
    dispatch(setUpdateInslugItemData(index, "client_no", selectedOption ? selectedOption.value : null));
    dispatch(setUpdateInslugItemData(index, "client_product_id", selectedOption ? selectedOption.value : null));
  };

  const handleCreateOption = (inputValue, index) => {
    const newOption = {
      value: inputValue,
      label: (
        <div className="d-flex align-items-center">
          <Badge color="info" pill>{inputValue}</Badge>
        </div>
      )
    };
    setClientProducts([...clientProducts, newOption]);

    let updatedSelectedProducts = [...selectedProducts];
    updatedSelectedProducts[index] = newOption;
    //dispatch(setSelectedUpdateProducts(updatedSelectedProducts));
    dispatch(setUpdateInslugItemData(index, 'client_no', newOption.value));
  };

  const handleInputChange = (index, e) => {
    dispatch(setUpdateInslugItemData(index, e.target.name, e.target.value.toLocaleUpperCase("tr")));
  };

  const addNewRow = () => {
    dispatch(addUpdateInslugItemRow());
    //dispatch(setSelectedUpdateProducts([...selectedProducts, null]));
  };

  const removeLastRow = () => {
    if (updateInslugItemsData.length > 0) {
      dispatch(removeUpdateLastInslugItemRow());
      //dispatch(setSelectedUpdateProducts((prevSelectedProducts) => prevSelectedProducts.slice(0, -1)));
    }
  };

  const renderProductLabel = (option) => {
    return (
      <div className="d-flex align-items-center">
        <Badge color="info" pill>{option.clientNo}</Badge>
        <Badge color="warning" pill className="mx-2">{option.productNo}</Badge>
        <Badge color="success" pill>{option.originCountry}</Badge>
      </div>
    );
  };

  return (
    <Card className="p-4 rounded-lg">
      <div className="d-flex mb-3 justify-content-between">
        <h4>{props.t("Product Information")}</h4>
        <div>
          <Button className="btn btn-md mx-2" color="success" onClick={addNewRow}>+</Button>
          <Button className='btn btn-md' color="danger" onClick={removeLastRow}>-</Button>
        </div>
      </div>
      <FormGroup>
        <Table>
          <thead>
            <tr>
              <th className='col-5'>{props.t("Client Product")}</th>
              <th>{props.t("Container No")}</th>
              <th>{props.t("Leverage Charge")}</th>
              <th className='col-3'>{props.t("Description")}</th>
            </tr>
          </thead>
          <tbody>
            {updateInslugItemsData.map((item, index) => (
              <tr key={index}>
                <td>
                  <DynamicCreatableSelect
                    name="client_product_id"
                    value={clientProducts.find(option => option.value === item.client_product_id)}
                    /* value={selectedProducts[index] || clientProducts.find(option => option.value === item.client_product_id)} */
                    options={clientProducts}
                    onChange={(selectedOption) => handleSelectChange(index, 'client_product_id', selectedOption)}
                    onCreateOption={(inputValue) => handleCreateOption(inputValue, index)}
                    placeholder={props.t("Select client product")}
                    noOptionsMessage={() => props.t("no-option-message")}
                    getOptionLabel={renderProductLabel} // Görsel olarak etiketi şekillendir
                    onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                    filterOption={(option, inputValue) => {
                      const clientNo = option.data.clientNo || "";
                      const companyName = option.data.companyName || "";
                      const productNo = option.data.productNo || "";
                      const productName = option.data.productName || "";

                      const clientNoMatch = clientNo.toLowerCase().includes(inputValue.toLowerCase());
                      const companyNameMatch = companyName.toLowerCase().includes(inputValue.toLowerCase());
                      const productNoMatch = productNo.toLowerCase().includes(inputValue.toLowerCase());
                      const productNameMatch = productName.toLowerCase().includes(inputValue.toLowerCase());

                      return clientNoMatch || companyNameMatch || productNoMatch || productNameMatch;
                    }}
                  />
                </td>
                <td>
                  <DynamicInput
                    type="text"
                    name="container_no"
                    value={item.container_no}
                    onChange={(e) => handleInputChange(index, e)}
                    placeholder={props.t("Enter container no")}
                    required
                  />
                </td>
                <td>
                  <DynamicInput
                    type="text"
                    name="leverage_charge"
                    value={item.leverage_charge}
                    onChange={(e) => handleInputChange(index, e)}
                    placeholder={props.t("Enter leverage charge information")}
                  />
                </td>
                <td>
                  <DynamicInput
                    type="text"
                    name="description"
                    value={item.description}
                    onChange={(e) => handleInputChange(index, e)}
                    placeholder={props.t("Enter description")}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </FormGroup>
    </Card>
  );
};

UpdateInslugItem.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation()(UpdateInslugItem)