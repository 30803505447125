import React, { useState, useEffect } from 'react';
import { Button, ButtonGroup, Input } from 'reactstrap';
import { FaEdit, FaTrash, FaCheck, FaTimes } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { setCompanies } from '../../store/company/actions'; // Redux actions
import { setExternCompanies } from 'store/externCompany/actions';
import { deleteCompany, deleteExternCompany, getCompanies, getExternCompanies, updateCompany, updateExternCompany } from 'service/companyService'; // API functions
import { openModal, closeModal } from '../../store/modal/actions'; // Modal actions
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AcceptModal from 'components/Common/Modals/acceptModal';
import jsPDF from 'jspdf';
import 'jspdf-autotable'; // PDF export için
import * as XLSX from 'xlsx'; // Excel export için
import '../../styles/custom/datatable.css'
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import excel from "../../assets/icons/excel-white.png";
import customStyles from 'styles/customDatatableStyle';
import customDatatableStyles from 'styles/customDatatableStyle';

const CompanyList = props => {
  const dispatch = useDispatch();

  const [editingRow, setEditingRow] = useState(null); // Row being edited
  const [formData, setFormData] = useState({}); // State for form data
  const [searchText, setSearchText] = useState(''); // State for search text
  const [selectedOption, setSelectedOption] = useState('companies'); // Başlangıçta companies seçili

  const companies = useSelector((state) => state.companies?.companies || []);
  const externCompanies = useSelector((state) => state.externCompanies?.externCompanies || []);

  const [filteredCompanies, setFilteredCompanies] = useState([]);

  // İlk başta hem companies hem externCompanies verilerini alalım
  useEffect(() => {
    const fetchData = async () => {
      try {
        const companyList = await getCompanies();
        dispatch(setCompanies(companyList));
        const externCompanyList = await getExternCompanies();
        dispatch(setExternCompanies(externCompanyList));
        setFilteredCompanies(companyList);

      } catch (error) {
        console.error('Error fetching companies:', error);
        toast.error(props.t("toast-error-fetch-data"), { position: "top-right" });
      }
    };
    fetchData();
  }, [dispatch]);

  // Seçime göre filteredCompanies'i güncelle
  useEffect(() => {
    if (selectedOption === "companies") {
      setFilteredCompanies(companies);
    } else {
      setFilteredCompanies(externCompanies);
    }
  }, [selectedOption, companies, externCompanies]);

  // Search text'e göre filtreleme işlemi
  useEffect(() => {
    const dataToFilter = selectedOption === 'companies' ? companies : externCompanies;
    const filteredData = dataToFilter.filter((company) =>
      company.name.toLowerCase().includes(searchText.toLowerCase()) ||
      company.client_no.toLowerCase().includes(searchText.toLowerCase()) ||
      company.email?.toLowerCase().includes(searchText.toLowerCase()) ||
      company.phone?.toLowerCase().includes(searchText.toLowerCase()) ||
      company.address?.toLowerCase().includes(searchText.toLowerCase()) ||
      company.country?.toLowerCase().includes(searchText.toLowerCase()) ||
      company.city?.toLowerCase().includes(searchText.toLowerCase()) ||
      company.iban?.toLowerCase().includes(searchText.toLowerCase()) ||
      company.tax_no?.toLowerCase().includes(searchText.toLowerCase()) ||
      company.tax_office?.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredCompanies(filteredData);
  }, [searchText, companies, externCompanies, selectedOption]);

  const handleEdit = (rowIndex) => {
    setEditingRow(rowIndex); // Set the row for editing
    setFormData(filteredCompanies[rowIndex]); // Set the form data for the current row
  };

  const handleChange = (option) => {
    setSelectedOption(option);
  };

  const handleSave = (rowIndex) => {
    dispatch(
      openModal({
        title: props.t("modal-update-title"),
        body: props.t("modal-update-body"),
        onConfirm: async () => {
          try {
            if (selectedOption === "companies") {
              await updateCompany(formData.id, formData); // Call API to update company
              toast.success(props.t("toast-success-update-data"), { position: "top-right" });

              const updatedCompanies = await getCompanies(); // Fetch updated companies
              dispatch(setCompanies(updatedCompanies)); // Update Redux state
              setFilteredCompanies(updatedCompanies); // Update filtered companies
              setEditingRow(null); // Exit edit mode
              dispatch(closeModal()); // Close the modal after successful update
            } else {
              await updateExternCompany(formData.id, formData); // Call API to update company
              toast.success(props.t("toast-success-update-data"), { position: "top-right" });

              const updatedCompanies = await getExternCompanies(); // Fetch updated companies
              dispatch(setExternCompanies(updatedCompanies)); // Update Redux state
              setFilteredCompanies(updatedCompanies); // Update filtered companies
              setEditingRow(null); // Exit edit mode
              dispatch(closeModal()); // Close the modal after successful update
            }

          } catch (error) {
            console.error('Error updating company:', error);
            toast.error(props.t("toast-error-update-data"), { position: "top-right" });
            dispatch(closeModal()); // Close the modal after failure
          }
        }
      })
    );
  };

  const handleDelete = (id) => {
    dispatch(
      openModal({
        title: props.t("modal-delete-title"),
        body: props.t("modal-delete-body"),
        onConfirm: async () => {
          try {
            if (selectedOption === "companies") {
              await deleteCompany(id); // Delete company
              toast.success(props.t("toast-success-delete-data"), { position: "top-right" });

              const updatedCompanies = await getCompanies(); // Fetch updated companies
              dispatch(setCompanies(updatedCompanies)); // Update Redux state
              setFilteredCompanies(updatedCompanies); // Update filtered companies
              dispatch(closeModal()); // Close the modal after successful update
            } else {
              await deleteExternCompany(id); // Delete company
              toast.success(props.t("toast-success-delete-data"), { position: "top-right" });

              const updatedCompanies = await getExternCompanies(); // Fetch updated companies
              dispatch(setExternCompanies(updatedCompanies)); // Update Redux state
              setFilteredCompanies(updatedCompanies); // Update filtered companies
              dispatch(closeModal()); // Close the modal after successful update
            }
          } catch (error) {
            console.error('Şirket silinirken bir hata oluştu:', error);
            toast.error(props.t("toast-error-delete-data"), { position: "top-right" });
            dispatch(closeModal()); // Close the modal after failure
          }
        }
      })
    );
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const exportToExcel = () => {
    const column1Name = props.t("Client No");
    const column2Name = props.t("Name");
    const column3Name = props.t("Mail");
    const column4Name = props.t("Phone");
    const column5Name = props.t("Address");
    const column6Name = props.t("Country");
    const column7Name = props.t("City");
    const column8Name = props.t("IBAN");
    const column9Name = props.t("Tax No");
    const column10Name = props.t("Tax Office");

  
    const selectedColumns = filteredCompanies.map(itemCompanies => ({
      [column1Name]: itemCompanies.client_no, 
      [column2Name]: itemCompanies.name,
      [column3Name]: itemCompanies.email,
      [column4Name]: itemCompanies.phone,
      [column5Name]: itemCompanies.address,
      [column6Name]: itemCompanies.country,
      [column7Name]: itemCompanies.city,
      [column8Name]: itemCompanies.iban,
      [column9Name]: itemCompanies.tax_no,
      [column10Name]: itemCompanies.tax_office,
    }));

    const worksheet = XLSX.utils.json_to_sheet(selectedColumns);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, props.t("Companies"));
    XLSX.writeFile(workbook, props.t("Companies")+".xlsx");
  };

  const columns = [
    {
      name: props.t("Client No"),
      selector: (row, rowIndex) => editingRow === rowIndex ? (
        <Input
          type="text"
          name="client_no"
          value={formData.client_no}
          onChange={handleInputChange}
        />
      ) : (
        row.client_no
      ),
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Name"),
      selector: (row, rowIndex) => editingRow === rowIndex ? (
        <Input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
        />
      ) : (
        row.name
      ),
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Mail"),
      selector: (row, rowIndex) => editingRow === rowIndex ? (
        <Input
          type="text"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
        />
      ) : (
        row.email
      ),
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Phone"),
      selector: (row, rowIndex) => editingRow === rowIndex ? (
        <Input
          type="text"
          name="phone"
          value={formData.phone}
          onChange={handleInputChange}
        />
      ) : (
        row.phone
      ),
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Address"),
      selector: (row, rowIndex) => editingRow === rowIndex ? (
        <Input
          type="text"
          name="address"
          value={formData.address}
          onChange={handleInputChange}
        />
      ) : (
        row.address
      ),
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Country"),
      selector: (row, rowIndex) => editingRow === rowIndex ? (
        <Input
          type="text"
          name="country"
          value={formData.country}
          onChange={handleInputChange}
        />
      ) : (
        row.country
      ),
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("City"),
      selector: (row, rowIndex) => editingRow === rowIndex ? (
        <Input
          type="text"
          name="city"
          value={formData.city}
          onChange={handleInputChange}
        />
      ) : (
        row.city
      ),
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("IBAN"),
      selector: (row, rowIndex) => editingRow === rowIndex ? (
        <Input
          type="text"
          name="iban"
          value={formData.iban}
          onChange={handleInputChange}
        />
      ) : (
        row.iban
      ),
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Tax Number"),
      selector: (row, rowIndex) => editingRow === rowIndex ? (
        <Input
          type="text"
          name="tax_no"
          value={formData.tax_no}
          onChange={handleInputChange}
        />
      ) : (
        row.tax_no
      ),
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Tax Office"),
      selector: (row, rowIndex) => editingRow === rowIndex ? (
        <Input
          type="text"
          name="tax_office"
          value={formData.tax_office}
          onChange={handleInputChange}
        />
      ) : (
        row.tax_office
      ),
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Actions"),
      cell: (row, rowIndex) =>
        editingRow === rowIndex ? (
          <>
            <Button className='m-1 btn btn-sm' color="success" onClick={() => handleSave(rowIndex)}>
              <FaCheck /> {/* Save */}
            </Button>
            <Button className='m-1 btn btn-sm' color="danger" onClick={() => setEditingRow(null)}>
              <FaTimes /> {/* Cancel */}
            </Button>
          </>
        ) : (
          <>
            <Button color="link" onClick={() => handleEdit(rowIndex)}>
              <FaEdit color="green" size={18} />
            </Button>
            <Button color="link" onClick={() => handleDelete(row.id)}>
              <FaTrash color="red" size={18} />
            </Button>
          </>
        ),
    },
  ];

  const customPaginationOptions = {
    rowsPerPageText: props.t("Rows count"), // Burada yazıyı özelleştiriyoruz
    rangeSeparatorText: '/',
  };

  return (
    <div className="my-4">
      <h4 className="text-primary mb-4">{props.t("Company List")}</h4>

      <DataTable
        style={{
          border: "1px solid #ff6347" /* Yatay çizgi rengi - turuncu örneği */
        }}
        className='p-3'
        columns={columns}
        data={filteredCompanies}
        customStyles={customStyles}
        pagination
        noDataComponent={props.t("table-empty-message")} // Boş veri mesajını özelleştiriyoruz
        paginationComponentOptions={customPaginationOptions} // Özelleştirilmiş yazı ekledik
        highlightOnHover
        paginationLa
        subHeader
        subHeaderComponent={
          <div className='w-100 d-flex align-items-center justify-content-between mb-2'>
            <div>
              <ButtonGroup className='rounded-lg border'>
                <Button
                  color={selectedOption === 'companies' ? 'primary' : 'white'}
                  onClick={() => handleChange('companies')}
                >
                  {props.t("Companies")}
                </Button>
                <Button
                  color={selectedOption === 'extern-companies' ? 'primary' : 'white'}
                  onClick={() => handleChange('extern-companies')}
                >
                  {props.t("Extern Companies")}
                </Button>
              </ButtonGroup>
            </div>
            <div className='d-flex'>
              <Button className="me-2 p-0 " color="success p-2" onClick={exportToExcel}>
                <img src={excel} width={'24'} alt="" />
              </Button>
              <Input
                type="text"
                placeholder={props.t("Search")}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value.toLocaleUpperCase("tr"))} // Search input update
                style={{ width: '250px' }} // Smaller input field
              />
            </div>
          </div>
        }
      />

      {/* Modal rendered by Redux */}
      <AcceptModal />
    </div>
  );
};

CompanyList.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation()(CompanyList)