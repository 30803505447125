import React, { useState, useEffect } from 'react';
import ClientProductForm from './clientProductForm'; // Şirket oluşturma ve düzenleme formu
import ClientProductList from './clientProductList'; // Şirket listeleme bileşeni
import { Card } from 'reactstrap';
import { useTranslation } from 'react-i18next'; 
import { connect } from "react-redux";
import { setBreadcrumbItems } from "../../store/actions";

const Product = (props) => {
  const [refresh, setRefresh] = useState(false); // Şirket listesi yenileme durumu
  const { t } = useTranslation();
  const handleRefresh = () => {
    setRefresh(!refresh); // Form başarıyla tamamlandığında listeyi yeniden yüklemek için state değiştirme
  };

  document.title = t("Client Products");
  const breadcrumbItems = [];
  useEffect(() => {
    props.setBreadcrumbItems('', breadcrumbItems)
  },)

  return (
    <div>
      <Card className='shadow p-4 rounded-lg'>
        {/* Şirket oluşturma formu */}
        <ClientProductForm onSuccess={handleRefresh} />
      </Card>
      {/* Şirket listesi, her form gönderiminden sonra güncellenir */}
      <Card className='shadow p-4 rounded-lg'>
        <ClientProductList refresh={refresh} />
      </Card>
    </div>
  );
};

export default connect(null, { setBreadcrumbItems })(Product);